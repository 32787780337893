package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.components.esg.formcontrols.ESGRadioButtonWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.helpers.common.YesNoEnum
import com.ecosave.watch.portal.helpers.esg.TheOrgAndItsRepPracticesField
import com.ecosave.watch.portal.models.esg.TheOrgAndItsRepPracticesState
import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.ChangeEvent
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement
import web.html.HTMLInputElement

external interface Section22Props : Props {
    var onChangeRadio: (ChangeEvent<HTMLInputElement>) -> Unit
    var onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit
    var state: TheOrgAndItsRepPracticesState
}

val Section2_2 = FC<Section22Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onChangeRadio = props.onChangeRadio
    val state = props.state

    SectionMainTitle {
        title = "2-2 ENTITIES INCLUDED IN THE ORGANIZATION’S SUSTAINABILITY REPORTING"
    }

    Box {
        Label {
            label = "List all its entities included in sustainability reporting"
        }
        ESGTextAreaFieldWrapper {
            name = TheOrgAndItsRepPracticesField.SECTION_2_2_FIELD1.name
            value = state.section22Field1
            onChange = { event -> onChangeTextArea(event) }
        }
    }

    Box {
        Label {
            label =
                "Are there any differences between entities included in financial reporting and the entities included in sustainability reporting?"
        }
        ESGRadioButtonWrapper {
            name = TheOrgAndItsRepPracticesField.SECTION_2_2_FIELD2.name
            value = state.section22Field2
            onChange = { event, _ -> onChangeRadio(event) }
        }
    }

    Box {
        Label {
            label = "Does the organization consist of multiple entities? If yes, explain the approach for consolidating information"
        }
        ESGRadioButtonWrapper {
            name = TheOrgAndItsRepPracticesField.SECTION_2_2_FIELD3.name
            value = state.section22Field3
            onChange = { event, _ -> onChangeRadio(event) }
        }
    }

    if (state.section22Field3 == YesNoEnum.YES.description) {
        Box {
            className = ESGReportInputStyles.TABLE_WRAPPER.cssClass
            Box {
                className = ESGReportInputStyles.GRID_WRAPPER.cssClass
                Box {
                    Label {
                        label = "Does the approach involve adjustments to information for minority interests?"
                    }
                    ESGRadioButtonWrapper {
                        name = TheOrgAndItsRepPracticesField.SECTION_2_2_FIELD4.name
                        value = state.section22Field4
                        onChange = { event, _ -> onChangeRadio(event) }
                    }
                }
                Box {
                    Label {
                        label = "How does the approach consider mergers, acquisitions, and disposal of entities or parts of entities?"
                    }
                    ESGTextAreaFieldWrapper {
                        name = TheOrgAndItsRepPracticesField.SECTION_2_2_FIELD5.name
                        value = state.section22Field5
                        onChange = { event -> onChangeTextArea(event) }
                    }
                }
                Box {
                    Label {
                        label = "Does the approach differ across disclosures in this Standard and across material topics"
                    }
                    ESGRadioButtonWrapper {
                        name = TheOrgAndItsRepPracticesField.SECTION_2_2_FIELD6.name
                        value = state.section22Field6
                        onChange = { event, _ -> onChangeRadio(event) }
                    }
                }
            }
        }
    }
}