package com.ecosave.watch.portal.components.esg.economicperformance

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.*
import com.ecosave.watch.portal.helpers.esg.*
import com.ecosave.watch.portal.models.esg.EconomicPerformanceState
import com.ecosave.watch.portal.models.esg.RetirementPlans
import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles
import com.ecosave.watch.portal.styles.esg.EconomicPerformanceStyles
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.html.ReactHTML
import react.dom.onChange
import web.html.HTMLDivElement

external interface Section2013Props : Props {
    var onChange: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var state: EconomicPerformanceState
    var stateSetter: (EconomicPerformanceState) -> Unit
    var addRow: (String) -> Unit
    var deleteRow: (String, Int) -> Unit
}

val Section201_3 = FC<Section2013Props> { props ->

    val onChangeFunction = props.onChange
    val state = props.state
    val stateSetter = props.stateSetter

    SectionMainTitle {
        title = "201-3 DEFINED BENEFIT PLAN OBLIGATIONS AND OTHER RETIREMENT PLANS"
    }
    Box {
        className = ESGReportInputStyles.TABLE_WRAPPER.cssClass
        SectionSubHeading {
            subHeading = "How are your organization's defined benefit plan liabilities met?"
        }
        Box {
            className = EconomicPerformanceStyles.SECTION_201_3_TABLE.cssClass
            Label {
                label = "Organization's general resources"
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2013A1.name
                value = state.section2013A1
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            Label {
                label = "Separate fund"
            }
            ESGTextFieldWrapper {
                name =  EconomicPerformanceField.SECTION_2013A2.name
                value = state.section2013A2
                onChange = { event ->  onChangeFunction(event, null, null) }
            }
            Label {
                label = "Other (please specify)"
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2013A3.name
                value = state.section2013A3
                onChange = { event -> onChangeFunction(event, null, null) }
            }
        }

        SectionSubHeading {
            subHeading = "If liabilities are met by the organization's general resources:"
        }
        Box {
            className = EconomicPerformanceStyles.SECTION_201_3_TABLE.cssClass
            Label {
                label = "What is the estimated value of your defined benefit plan liabilities?"
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2013A4.name
                value = state.section2013A4
                onChange = { event -> onChangeFunction(event, null, null) }
            }
        }

        SectionSubHeading {
            subHeading = "If liabilities are met by a separate fund:"
        }
        Box {
            className = EconomicPerformanceStyles.SECTION_201_3_TABLE.cssClass
            Label {
                label = "To what extent are the scheme's liabilities estimated to be covered by the assets set aside? "
            }
            ESGTextFieldWrapper {
                name =  EconomicPerformanceField.SECTION_2013A5.name
                value = state.section2013A5
                onChange = { event ->  onChangeFunction(event, null, null) }
            }
            Label {
                label = "What is the basis used to arrive at the estimate above?"
            }
            ESGTextFieldWrapper {
                name =  EconomicPerformanceField.SECTION_2013A6.name
                value = state.section2013A6
                onChange = { event ->  onChangeFunction(event, null, null) }
            }
            Label {
                label = "When estimate was made"
            }
            ESGTextFieldWrapper {
                name =  EconomicPerformanceField.SECTION_2013A7.name
                value = state.section2013A7
                onChange = { event ->  onChangeFunction(event, null, null) }
            }
            Label {
                label = "If a separate fund to pay for pension liabilities is not fully covered, please describe your organization's strategy to work towards full coverage."
            }
            ESGTextFieldWrapper {
                name =  EconomicPerformanceField.SECTION_2013A8.name
                value = state.section2013A8
                onChange = { event ->  onChangeFunction(event, null, null) }
            }
            Label {
                label = "Is there a specific timescale by which you aim to achieve full coverage? If so, please clarify."
            }
            ESGTextFieldWrapper {
                name =  EconomicPerformanceField.SECTION_2013A9.name
                value = state.section2013A9
                onChange = { event ->  onChangeFunction(event, null, null) }
            }
        }
    }

    Box {
                className = ESGReportInputStyles.STICKY_HEADER.cssClass
                DynamicTableWrapper {
                    addRows = {
                        props.addRow(EconomicPerformanceField.SECTION_2013_RETIREMENTPLAN.fieldName)
                        state.retirementPlansList.add(RetirementPlans())
                        stateSetter(state)
                    }
                    items = state.retirementPlansList
                    action = DynamicTableAction.ADD
                    variant = DynamicTableVariant.TABLE
                    addRowBtnText = "retirement plan"
                    Box {
                        className = EconomicPerformanceStyles.SECTION_201_2_RISKS_WRAPPER.cssClass
                        SectionSubHeading {
                            subHeading = "For each retirement plan offered, please specify:"
                        }
                    }
                }
        }

    state.retirementPlansList.forEachIndexed{index, it ->
        DynamicTableWrapper {
            deleteRows = {
                        props.deleteRow(EconomicPerformanceField.SECTION_2013_RETIREMENTPLAN.fieldName,index)
                        state.retirementPlansList.removeAt(index)
                        stateSetter(state)
                    }
            items = state.retirementPlansList
            action = DynamicTableAction.DELETE

            Box {
                key = "index$index"
                className = EconomicPerformanceStyles.SECTION_201_2_RISKS_TABLE.cssClass
                SectionSubHeading {
                    subHeading = "Retirement Plan ${index + 1}"
                }
                ReactHTML.label {
                    className = ESGReportInputStyles.LABEL.cssClass
                    +"Plan Name"
                }
                ESGTextFieldWrapper {
                    name = EconomicPerformanceField.SECTION_2013A10.name
                    value = it.section2013A10
                    onChange = { event -> onChangeFunction(event, index, EconomicPerformanceField.SECTION_2013_RETIREMENTPLAN.fieldName) }
                }
                ReactHTML.label {
                            className = ESGReportInputStyles.LABEL.cssClass
                            +"Percentage of salary contributed by the employee"
                        }
                ESGNumberFieldWrapper {
                            name = EconomicPerformanceField.SECTION_2013A11.name
                            value = it.section2013A11
                            onChange = { event -> onChangeFunction(event, index, EconomicPerformanceField.SECTION_2013_RETIREMENTPLAN.fieldName) }
                        }
                ReactHTML.label {
                            className = ESGReportInputStyles.LABEL.cssClass
                            +"Percentage of salary contributed by the employer"
                        }
                ESGNumberFieldWrapper {
                            name = EconomicPerformanceField.SECTION_2013A12.name
                            value = it.section2013A12
                            onChange = { event -> onChangeFunction(event, index, EconomicPerformanceField.SECTION_2013_RETIREMENTPLAN.fieldName) }
                        }

                SectionSubHeading {
                            subHeading = "What is the level of employee participation in this retirement plan? Please indicate:"
                        }
                ReactHTML.label {
                            className = ESGReportInputStyles.LABEL.cssClass
                            +"Mandatory or Voluntary?"
                        }
                ESGTextFieldWrapper {
                            name = EconomicPerformanceField.SECTION_2013A13.name
                            value = it.section2013A13
                            onChange = { event -> onChangeFunction(event, index, EconomicPerformanceField.SECTION_2013_RETIREMENTPLAN.fieldName) }
                        }
                ReactHTML.label {
                            className = ESGReportInputStyles.LABEL.cssClass
                            +"Regional, Country-based, or other?"
                        }
                ESGTextFieldWrapper {
                            name = EconomicPerformanceField.SECTION_2013A14.name
                            value = it.section2013A14
                            onChange = { event -> onChangeFunction(event, index, EconomicPerformanceField.SECTION_2013_RETIREMENTPLAN.fieldName) }
                        }
                ReactHTML.label {
                            className = ESGReportInputStyles.LABEL.cssClass
                            +"Whether the scheme has a financial impact (impact on company valuations)"
                        }
                ESGTextFieldWrapper {
                            name = EconomicPerformanceField.SECTION_2013A15.name
                            value = it.section2013A15
                            onChange = { event -> onChangeFunction(event, index, EconomicPerformanceField.SECTION_2013_RETIREMENTPLAN.fieldName) }
                        }
            }
        }
    }

}