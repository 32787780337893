package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles
import mui.system.Box
import react.FC
import react.Props
import react.dom.html.ReactHTML

external interface OccupationHealthAndSafetyComponentProps : Props {

}

val OccupationHealthAndSafetyComponent = FC<OccupationHealthAndSafetyComponentProps> { props ->
    Box {
        ReactHTML.label {
            className = ESGReportInputStyles.ESG_FIRST_FONT.cssClass
            +"Occupation Health and Safety"
        }
    }
}