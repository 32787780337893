package com.ecosave.watch.portal.components.esg.formcontrols

import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles
import react.FC
import react.Props
import react.dom.html.ReactHTML

external interface SectionMainTitleProps : Props {
    var title: String
}

val SectionMainTitle = FC<SectionMainTitleProps> {
    ReactHTML.label {
        className = ESGReportInputStyles.MAIN_TITLE.cssClass
        +it.title
    }
}

external interface SectionSubHeadingProps : Props {
    var subHeading: String
}

val SectionSubHeading = FC<SectionSubHeadingProps> {
    ReactHTML.label {
        className = ESGReportInputStyles.SUB_HEADING.cssClass
        +it.subHeading
    }
}

