package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.components.esg.formcontrols.ESGRadioButtonWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.helpers.common.YesNoEnum
import com.ecosave.watch.portal.helpers.esg.TheOrgAndItsRepPracticesField
import com.ecosave.watch.portal.models.esg.TheOrgAndItsRepPracticesState
import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.ChangeEvent
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement
import web.html.HTMLInputElement

external interface Section25Props : Props {
    var onChangeRadio: (ChangeEvent<HTMLInputElement>) -> Unit
    var onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit
    var state: TheOrgAndItsRepPracticesState
}

val Section2_5 = FC<Section25Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onChangeRadio = props.onChangeRadio
    val state = props.state

    SectionMainTitle {
        title = "2-5 EXTERNAL ASSURANCE"
    }

    Box {
        Label {
            label = "Policy and practice for seeking external assurance"
        }
        ESGTextAreaFieldWrapper {
            name = TheOrgAndItsRepPracticesField.SECTION_2_5_FIELD1.name
            value = state.section25Field1
            onChange = { event -> onChangeTextArea(event) }
        }
    }

    Box {
        Label {
            label = "Are the highest governance body and senior executives involved? How?"
        }
        ESGTextAreaFieldWrapper {
            name = TheOrgAndItsRepPracticesField.SECTION_2_5_FIELD2.name
            value = state.section25Field2
            onChange = { event -> onChangeTextArea(event) }
        }
    }

    Box {
        Label {
            label = "If externally assured:"
        }
        ESGRadioButtonWrapper {
            name = TheOrgAndItsRepPracticesField.SECTION_2_5_FIELD3.name
            value = state.section25Field3
            onChange = { event, _ -> onChangeRadio(event) }
        }
    }

    if (state.section25Field3 == YesNoEnum.YES.description) {
        Box {
            className = ESGReportInputStyles.TABLE_WRAPPER.cssClass
            Box {
                className = ESGReportInputStyles.GRID_WRAPPER.cssClass
                Box {
                    Label {
                        label = "Link/reference to the external assurance report(s) or assurance statement(s)"
                    }
                    ESGTextAreaFieldWrapper {
                        name = TheOrgAndItsRepPracticesField.SECTION_2_5_FIELD4.name
                        value = state.section25Field4
                        onChange = { event -> onChangeTextArea(event) }
                    }
                }
                Box {
                    Label {
                        label =
                            "Describe what has been assured and on what basis, including the assurance standards used, the level of assurance obtained, and any limitations of the assurance process"
                    }
                    ESGTextAreaFieldWrapper {
                        name = TheOrgAndItsRepPracticesField.SECTION_2_5_FIELD5.name
                        value = state.section25Field5
                        onChange = { event -> onChangeTextArea(event) }
                    }
                }
                Box {
                    Label {
                        label = "Relationship between the organization and the assurance provider"
                    }
                    ESGTextAreaFieldWrapper {
                        name = TheOrgAndItsRepPracticesField.SECTION_2_5_FIELD6.name
                        value = state.section25Field6
                        onChange = { event -> onChangeTextArea(event) }
                    }
                }
            }
        }
    }
}