package com.ecosave.watch.portal.components.esg.economicperformance

import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.EconomicPerformanceField
import com.ecosave.watch.portal.models.esg.EconomicPerformanceState
import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles
import com.ecosave.watch.portal.styles.esg.EconomicPerformanceStyles
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.html.ReactHTML
import react.dom.onChange
import web.html.HTMLDivElement

external interface Section2011Props : Props {
    var onChange: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var state: EconomicPerformanceState
}

val Section201_1 = FC<Section2011Props> { props ->

    val onChangeFunction = props.onChange
    val state = props.state

    SectionMainTitle {
        title = "201-1 DIRECT ECONOMIC VALUE GENERATED AND DISTRIBUTED (EVG&D)"
    }
    Box {
        className = ESGReportInputStyles.TABLE_WRAPPER.cssClass
        Box {
            className = EconomicPerformanceStyles.SECTION_201_1_TABLE.cssClass
            TableHeader {
                header = "Total organization/country/region/market level"
            }
            TableHeader {
                header = "Current year"
            }
            TableHeader {
                header = "Previous year(s)"
            }
            Label {
                label = "Direct economic value generated and distributed"
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2011A1.name
                value = state.section2011A1
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2011B1.name
                value = state.section2011B1
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            Label {
                label = "Direct economic value generated (Revenue)"
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2011A2.name
                value = state.section2011A2
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2011B2.name
                value = state.section2011B2
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            Label {
                label = "Economic value distributed (Costs)"
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2011A3.name
                value = state.section2011A3
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2011B3.name
                value = state.section2011B3
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            Label {
                label = "Operating costs"
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2011A4.name
                value = state.section2011A4
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2011B4.name
                value = state.section2011B4
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            Label {
                label = "Employee wages and benefits"
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2011A5.name
                value = state.section2011A5
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2011B5.name
                value = state.section2011B5
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            Label {
                label = "Payments to providers of capital"
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2011A6.name
                value = state.section2011A6
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2011B6.name
                value = state.section2011B6
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            Label {
                label = "Payments to government by country"
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2011A7.name
                value = state.section2011A7
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2011B7.name
                value = state.section2011B7
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            Label {
                label = "Community investments"
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2011A8.name
                value = state.section2011A8
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2011B8.name
                value = state.section2011B8
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            Label {
                label = "Economic value retained (Profits/Losses)"
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2011A9.name
                value = state.section2011A9
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2011B9.name
                value = state.section2011B9
                onChange = { event -> onChangeFunction(event, null, null) }
            }
        }
    }
    Box {
        ReactHTML.label {
            className = ESGReportInputStyles.LABEL.cssClass
            +"Where significant, report EVG&D separately at country, regional, or market levels, and the criteria used for defining significance."
        }
        ESGTextAreaFieldWrapper {
            name = EconomicPerformanceField.SECTION_2011_FIELD1.name
            value = state.section2011Field1
            onChange = { event -> onChangeFunction(event, null, null) }
        }
    }
    Box {
        ReactHTML.label {
            className = ESGReportInputStyles.LABEL.cssClass
            +"Provide criteria for defining significance if EVG&D reports are separated into different categories. If applicable, data should come from the organization’s audited financial or profit and loss (P&L) statement."
        }
        ESGTextAreaFieldWrapper {
            name = EconomicPerformanceField.SECTION_2011_FIELD2.name
            value = state.section2011Field2
            onChange = { event -> onChangeFunction(event, null, null) }
        }
    }
}