package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.esg.EsgSubSection
import com.ecosave.watch.portal.helpers.esg.GovernanceField
import com.ecosave.watch.portal.helpers.esg.autoSaveEsgField
import com.ecosave.watch.portal.helpers.common.isValidInteger
import com.ecosave.watch.portal.helpers.esg.resetPreviousPatchState
import com.ecosave.watch.portal.helpers.esg.savingErrorMessage
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.GovernanceState
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.Props
import react.StateSetter
import react.dom.events.ChangeEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement
import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles as styles


external interface GovernanceComponentProps : Props {
    var esgReportStateProps: EsgReportState
    var esgReportStateSetterProps: StateSetter<EsgReportState>
    var setInputState: StateSetter<CurrentInputState>
    var setNotificationMessage: StateSetter<String>
    var setNotificationStatus: StateSetter<NotificationStatus>
    var setOpenAlertNotifications: StateSetter<Boolean>
}


val GovernanceComponent = FC<GovernanceComponentProps> { props ->

    val esgState = props.esgReportStateProps
    val esgStateSetter = props.esgReportStateSetterProps

    esgState.report?.governance?.let { state ->

        fun stateSetter(state: GovernanceState) {
            esgStateSetter(
                esgState.copy(
                    report = esgState.report.copy(
                        governance = state
                    )
                )
            )
        }

        val onChangeNumber: (FormEvent<HTMLDivElement>) -> Unit = { event ->
            val target = event.target as HTMLInputElement
            if (isValidInteger(target)) {
                val propertyName = GovernanceField.valueOf(target.name)
                val propertyValue = target.value.toIntOrNull()
                stateSetter(
                    state.copy(
                        section29A1 = if (propertyName == GovernanceField.SECTION_2_9_A1) propertyValue else state.section29A1,
                        section29A2 = if (propertyName == GovernanceField.SECTION_2_9_A2) propertyValue else state.section29A2,
                        section29A5 = if (propertyName == GovernanceField.SECTION_2_9_A5) propertyValue else state.section29A5,
                        section29A6 = if (propertyName == GovernanceField.SECTION_2_9_A6) propertyValue else state.section29A6,
                        section29A7 = if (propertyName == GovernanceField.SECTION_2_9_A7) propertyValue else state.section29A7,
                        section29A8 = if (propertyName == GovernanceField.SECTION_2_9_A8) propertyValue else state.section29A8
                    )
                )
                props.setInputState(
                    CurrentInputState(
                        childPath = propertyName.fieldName,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSubSection.GOVERNANCE.name,
                        reportName = esgState.reportFileName
                    )
                )
            }
        }

        val onChangeRadio: (ChangeEvent<HTMLInputElement>) -> Unit = { event ->
            resetPreviousPatchState(props.setInputState)
            var status: ApiCallStatus?
            val propertyName = GovernanceField.valueOf(event.target.name)
            val propertyValue = event.target.value
            stateSetter(
                state.copy(
                    section29A3 = if (propertyName == GovernanceField.SECTION_2_9_A3) propertyValue else state.section29A3,

                    section213A2 = if (propertyName == GovernanceField.SECTION_2_13_A2) propertyValue else state.section213A2,
                    section213A3 = if (propertyName == GovernanceField.SECTION_2_13_A3) propertyValue else state.section213A3,

                    section215A1 = if (propertyName == GovernanceField.SECTION_2_15_A1) propertyValue else state.section215A1,
                    section215A2 = if (propertyName == GovernanceField.SECTION_2_15_A2) propertyValue else state.section215A2,
                    section215A3 = if (propertyName == GovernanceField.SECTION_2_15_A3) propertyValue else state.section215A3,
                    section215A4 = if (propertyName == GovernanceField.SECTION_2_15_A4) propertyValue else state.section215A4,
                    section215A5 = if (propertyName == GovernanceField.SECTION_2_15_A5) propertyValue else state.section215A5,

                    section218A2 = if (propertyName == GovernanceField.SECTION_2_18_A2) propertyValue else state.section218A2

                )
            )
            mainScope.launch {
                status = autoSaveEsgField(
                    propertyName.fieldName,
                    JsonPrimitive(propertyValue),
                    EsgSubSection.GOVERNANCE.name,
                    esgState.reportFileName
                )
                savingErrorMessage(props.setNotificationMessage, props.setNotificationStatus, props.setOpenAlertNotifications, status)
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit = { event ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = GovernanceField.valueOf(target.name)
            val propertyValue = target.value
            stateSetter(
                state.copy(
                    section29A9 = if (propertyName == GovernanceField.SECTION_2_9_A9) propertyValue else state.section29A9,
                    section29A10 = if (propertyName == GovernanceField.SECTION_2_9_A10) propertyValue else state.section29A10,
                    section29Field1 = if (propertyName == GovernanceField.SECTION_2_9_FIELD1) propertyValue else state.section29Field1,
                    section29Field2 = if (propertyName == GovernanceField.SECTION_2_9_FIELD2) propertyValue else state.section29Field2,

                    section210A1 = if (propertyName == GovernanceField.SECTION_2_10_A1) propertyValue else state.section210A1,
                    section210A2 = if (propertyName == GovernanceField.SECTION_2_10_A2) propertyValue else state.section210A2,
                    section210A3 = if (propertyName == GovernanceField.SECTION_2_10_A3) propertyValue else state.section210A3,
                    section210A4 = if (propertyName == GovernanceField.SECTION_2_10_A4) propertyValue else state.section210A4,
                    section210A5 = if (propertyName == GovernanceField.SECTION_2_10_A5) propertyValue else state.section210A5,
                    section210A6 = if (propertyName == GovernanceField.SECTION_2_10_A6) propertyValue else state.section210A6,
                    section210Field1 = if (propertyName == GovernanceField.SECTION_2_10_FIELD1) propertyValue else state.section210Field1,

                    section211A1 = if (propertyName == GovernanceField.SECTION_2_11_A1) propertyValue else state.section211A1,
                    section211A2 = if (propertyName == GovernanceField.SECTION_2_11_A2) propertyValue else state.section211A2,

                    section212A1 = if (propertyName == GovernanceField.SECTION_2_12_A1) propertyValue else state.section212A1,
                    section212A2 = if (propertyName == GovernanceField.SECTION_2_12_A2) propertyValue else state.section212A2,
                    section212A3 = if (propertyName == GovernanceField.SECTION_2_12_A3) propertyValue else state.section212A3,
                    section212A4 = if (propertyName == GovernanceField.SECTION_2_12_A4) propertyValue else state.section212A4,
                    section212A5 = if (propertyName == GovernanceField.SECTION_2_12_A5) propertyValue else state.section212A5,

                    section213A1 = if (propertyName == GovernanceField.SECTION_2_13_A1) propertyValue else state.section213A1,
                    section213A4 = if (propertyName == GovernanceField.SECTION_2_13_A4) propertyValue else state.section213A4,

                    section214A1 = if (propertyName == GovernanceField.SECTION_2_14_A1) propertyValue else state.section214A1,
                    section214A2 = if (propertyName == GovernanceField.SECTION_2_14_A2) propertyValue else state.section214A2,

                    section215A6 = if (propertyName == GovernanceField.SECTION_2_15_A6) propertyValue else state.section215A6,

                    section216A1 = if (propertyName == GovernanceField.SECTION_2_16_A1) propertyValue else state.section216A1,
                    section216A2 = if (propertyName == GovernanceField.SECTION_2_16_A2) propertyValue else state.section216A2,

                    section217A1 = if (propertyName == GovernanceField.SECTION_2_17_A1) propertyValue else state.section217A1,

                    section218A1 = if (propertyName == GovernanceField.SECTION_2_18_A1) propertyValue else state.section218A1,
                    section218A3 = if (propertyName == GovernanceField.SECTION_2_18_A3) propertyValue else state.section218A3,
                    section218A4 = if (propertyName == GovernanceField.SECTION_2_18_A4) propertyValue else state.section218A4,

                    section219A1 = if (propertyName == GovernanceField.SECTION_2_19_A1) propertyValue else state.section219A1,
                    section219A2 = if (propertyName == GovernanceField.SECTION_2_19_A2) propertyValue else state.section219A2,
                    section219A3 = if (propertyName == GovernanceField.SECTION_2_19_A3) propertyValue else state.section219A3,
                    section219A4 = if (propertyName == GovernanceField.SECTION_2_19_A4) propertyValue else state.section219A4,
                    section219A5 = if (propertyName == GovernanceField.SECTION_2_19_A5) propertyValue else state.section219A5,
                    section219A6 = if (propertyName == GovernanceField.SECTION_2_19_A6) propertyValue else state.section219A6,
                    section219A7 = if (propertyName == GovernanceField.SECTION_2_19_A7) propertyValue else state.section219A7,

                    section220A1 = if (propertyName == GovernanceField.SECTION_2_20_A1) propertyValue else state.section220A1,
                    section220A2 = if (propertyName == GovernanceField.SECTION_2_20_A2) propertyValue else state.section220A2,
                    section220A3 = if (propertyName == GovernanceField.SECTION_2_20_A3) propertyValue else state.section220A3,
                    section220A4 = if (propertyName == GovernanceField.SECTION_2_20_A4) propertyValue else state.section220A4,
                    section220A5 = if (propertyName == GovernanceField.SECTION_2_20_A5) propertyValue else state.section220A5
                )
            )
            props.setInputState(
                CurrentInputState(
                    childPath = propertyName.fieldName,
                    value = JsonPrimitive(propertyValue.trim()),
                    objectName = EsgSubSection.GOVERNANCE.name,
                    reportName = esgState.reportFileName
                )
            )
        }

        Box {
            className = styles.SECTION_MAIN_LAYOUT.cssClass
            Section2_9 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
                this.onChangeRadio = onChangeRadio
                this.onChangeNumber = onChangeNumber
            }
            Section2_10 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
            }
            Section2_11 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
            }
            Section2_12 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
            }
            Section2_13 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
                this.onChangeRadio = onChangeRadio
            }
            Section2_14 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
            }
            Section2_15 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
                this.onChangeRadio = onChangeRadio
            }
            Section2_16 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
            }
            Section2_17 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
            }
            Section2_18 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
                this.onChangeRadio = onChangeRadio
            }
            Section2_19 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
            }
            Section2_20 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
            }
        }
    }
}
