package com.ecosave.watch.portal.components.esg.economicperformance

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.SectionSubHeading
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.EconomicPerformanceField
import com.ecosave.watch.portal.models.esg.EconomicPerformanceState
import com.ecosave.watch.portal.models.esg.Section2012Opportunity
import com.ecosave.watch.portal.models.esg.Section2012Risk
import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles
import com.ecosave.watch.portal.styles.esg.EconomicPerformanceStyles
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.html.ReactHTML
import react.dom.onChange
import web.html.HTMLDivElement

external interface Section2012Props : Props {
    var onChange: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var state: EconomicPerformanceState
    var stateSetter: (EconomicPerformanceState) -> Unit
    var addRow: (String) -> Unit
    var deleteRow: (String, Int) -> Unit
}

val Section201_2 = FC<Section2012Props> { props ->

    val onChangeFunction = props.onChange
    val state = props.state
    val stateSetter = props.stateSetter

    SectionMainTitle {
        title = "201-2 FINANCIAL IMPLICATIONS AND OTHER RISKS AND OPPORTUNITIES DUE TO CLIMATE CHANGE"
    }
    SectionSubHeading {
        subHeading =
            "Risks and opportunities posed by climate change that have the potential to generate substantive changes in operations, revenue, or expenditure"
    }

    Box {
        className = ESGReportInputStyles.STICKY_HEADER.cssClass
        DynamicTableWrapper {
            addRows = {
                props.addRow(EconomicPerformanceField.SECTION_2012_RISK.fieldName)
                state.financialRisksList.add(Section2012Risk())
                stateSetter(state)
            }
            items = state.financialRisksList
            action = DynamicTableAction.ADD
            variant = DynamicTableVariant.TABLE
            addRowBtnText = "risk"
            Box {
                className = EconomicPerformanceStyles.SECTION_201_2_RISKS_WRAPPER.cssClass
                SectionSubHeading {
                    subHeading = "Risks"
                }
            }
        }
    }

        state.financialRisksList.forEachIndexed { index, it ->
            DynamicTableWrapper {
                deleteRows = {
                    props.deleteRow(EconomicPerformanceField.SECTION_2012_RISK.fieldName,index)
                    state.financialRisksList.removeAt(index)
                    stateSetter(state)
                }
                items = state.financialRisksList
                action = DynamicTableAction.DELETE
                Box {
                    key = "index$index"
                    className = EconomicPerformanceStyles.SECTION_201_2_RISKS_TABLE.cssClass
                    SectionSubHeading {
                        subHeading = "Risk ${index + 1}"
                    }
                    ReactHTML.label {
                        className = ESGReportInputStyles.LABEL.cssClass
                        +"Provide a description of the risk and its classification as either physical, regulatory, or other;"
                    }
                    ESGTextAreaFieldWrapper {
                        name = EconomicPerformanceField.SECTION_2012_RISK_A1.name
                        value = it.section2012RiskA1
                        onChange = { event -> onChangeFunction(event, index, EconomicPerformanceField.SECTION_2012_RISK.fieldName) }
                    }
                    ReactHTML.label {
                        className = ESGReportInputStyles.LABEL.cssClass
                        +"Provide a description of the impact associated with the risk."
                    }
                    ESGTextAreaFieldWrapper {
                        name = EconomicPerformanceField.SECTION_2012_RISK_A2.name
                        value = it.section2012RiskA2
                        onChange = { event -> onChangeFunction(event, index, EconomicPerformanceField.SECTION_2012_RISK.fieldName) }
                    }
                    ReactHTML.label {
                        className = ESGReportInputStyles.LABEL.cssClass
                        +"What were or are the financial implications of the risk before action is taken?"
                    }
                    ESGTextAreaFieldWrapper {
                        name = EconomicPerformanceField.SECTION_2012_RISK_A3.name
                        value = it.section2012RiskA3
                        onChange = { event -> onChangeFunction(event, index, EconomicPerformanceField.SECTION_2012_RISK.fieldName) }
                    }
                    ReactHTML.label {
                        className = ESGReportInputStyles.LABEL.cssClass
                        +"What are the methods used to manage the risk?"
                    }
                    ESGTextAreaFieldWrapper {
                        name = EconomicPerformanceField.SECTION_2012_RISK_A4.name
                        value = it.section2012RiskA4
                        onChange = { event -> onChangeFunction(event, index, EconomicPerformanceField.SECTION_2012_RISK.fieldName) }
                    }
                    ReactHTML.label {
                        className = ESGReportInputStyles.LABEL.cssClass
                        +"What are the costs of actions taken to manage the risk?"
                    }
                    ESGNumberFieldWrapper {
                        name = EconomicPerformanceField.SECTION_2012_RISK_A5.name
                        value = it.section2012RiskA5
                        onChange = { event -> onChangeFunction(event, index, EconomicPerformanceField.SECTION_2012_RISK.fieldName) }
                    }
                }
            }
        }

    Box {
        className = ESGReportInputStyles.STICKY_HEADER.cssClass
        DynamicTableWrapper {
            addRows = {
                props.addRow(EconomicPerformanceField.SECTION_2012_OPPORTUNITY.fieldName)
                state.financialOpportunitiesList.add(Section2012Opportunity())
                stateSetter(state)
            }
            items = state.financialOpportunitiesList
            action = DynamicTableAction.ADD
            variant = DynamicTableVariant.TABLE
            addRowBtnText = "opportunity"
            Box {
                className = EconomicPerformanceStyles.SECTION_201_2_RISKS_WRAPPER.cssClass
                SectionSubHeading {
                    subHeading = "Opportunities"
                }
            }
        }
    }

    state.financialOpportunitiesList.forEachIndexed { index, it ->
        DynamicTableWrapper {
            deleteRows = {
                props.deleteRow(EconomicPerformanceField.SECTION_2012_OPPORTUNITY.fieldName,index)
                state.financialOpportunitiesList.removeAt(index)
                stateSetter(state)
            }
            items = state.financialOpportunitiesList
            action = DynamicTableAction.DELETE
            Box {
                key = "index$index"
                className = EconomicPerformanceStyles.SECTION_201_2_RISKS_TABLE.cssClass
                SectionSubHeading {
                    subHeading = "Opportunity ${index + 1}"
                }
                ReactHTML.label {
                    className = ESGReportInputStyles.LABEL.cssClass
                    +"Provide a description of the opportunity and its classification as either physical, regulatory, or other;"
                }
                ESGTextAreaFieldWrapper {
                    name = EconomicPerformanceField.SECTION_2012_OPPORTUNITY_A1.name
                    value = it.section2012OpportunityA1
                    onChange = { event -> onChangeFunction(event, index, EconomicPerformanceField.SECTION_2012_OPPORTUNITY.fieldName) }
                }
                ReactHTML.label {
                    className = ESGReportInputStyles.LABEL.cssClass
                    +"Provide a description of the impact associated with the opportunity."
                }
                ESGTextAreaFieldWrapper {
                    name = EconomicPerformanceField.SECTION_2012_OPPORTUNITY_A2.name
                    value = it.section2012OpportunityA2
                    onChange = { event -> onChangeFunction(event, index, EconomicPerformanceField.SECTION_2012_OPPORTUNITY.fieldName) }
                }
                ReactHTML.label {
                    className = ESGReportInputStyles.LABEL.cssClass
                    +"What were or are the financial implications of the opportunity before action is taken?"
                }
                ESGTextAreaFieldWrapper {
                    name = EconomicPerformanceField.SECTION_2012_OPPORTUNITY_A3.name
                    value = it.section2012OpportunityA3
                    onChange = { event -> onChangeFunction(event, index, EconomicPerformanceField.SECTION_2012_OPPORTUNITY.fieldName) }
                }
                ReactHTML.label {
                    className = ESGReportInputStyles.LABEL.cssClass
                    +"What are the methods used to manage the opportunity?"
                }
                ESGTextAreaFieldWrapper {
                    name = EconomicPerformanceField.SECTION_2012_OPPORTUNITY_A4.name
                    value = it.section2012OpportunityA4
                    onChange = { event -> onChangeFunction(event, index, EconomicPerformanceField.SECTION_2012_OPPORTUNITY.fieldName) }
                }
                ReactHTML.label {
                    className = ESGReportInputStyles.LABEL.cssClass
                    +"What are the costs of actions taken to manage the opportunity?"
                }
                ESGNumberFieldWrapper {
                    name = EconomicPerformanceField.SECTION_2012_OPPORTUNITY_A5.name
                    value = it.section2012OpportunityA5
                    onChange = { event -> onChangeFunction(event, index, EconomicPerformanceField.SECTION_2012_OPPORTUNITY.fieldName) }
                }
            }
        }
    }

    Box {
        ReactHTML.label {
            className = ESGReportInputStyles.LABEL.cssClass
            +"If the organization does not have a system in place to calculate the financial implications or costs, or to make revenue projections, report its plans and timeline to develop the necessary systems:"
        }
        ESGTextAreaFieldWrapper {
            name = EconomicPerformanceField.SECTION_2012_FIELD1.name
            value = state.section2012Field1
            onChange = { event -> onChangeFunction(event, null, null) }
        }
    }

}