package com.ecosave.watch.portal.components.esg.economicperformance

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.*
import com.ecosave.watch.portal.helpers.common.YesNoEnum
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.EconomicPerformanceField
import com.ecosave.watch.portal.models.esg.CountriesAndValues
import com.ecosave.watch.portal.models.esg.EconomicPerformanceState
import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles
import com.ecosave.watch.portal.styles.esg.EconomicPerformanceStyles
import com.ecosave.watch.portal.styles.esg.MarketPresence
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.ChangeEvent
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement
import web.html.HTMLInputElement

external interface Section2014Props : Props {
    var onChange: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var onChangeRadio: (ChangeEvent<HTMLInputElement>) -> Unit
    var state: EconomicPerformanceState
    var stateSetter: (EconomicPerformanceState) -> Unit
    var addRow: (String) -> Unit
    var deleteRow: (String, Int) -> Unit
}

val Section201_4 = FC<Section2014Props> { props ->

    val onChangeRadio = props.onChangeRadio
    val onChangeFunction = props.onChange
    val state = props.state
    val stateSetter = props.stateSetter

    SectionMainTitle {
        title = "201-4 FINANCIAL ASSISTANCE RECEIVED FROM GOVERNMENT\n"
    }
    SectionSubHeading {
        subHeading = "Please complete the following table to report the total monetary value of financial assistance received during the reporting period. Where possible, please categorize the assistance and specify the providing government or agency."
    }
    Box {
        className = ESGReportInputStyles.TABLE_WRAPPER.cssClass
        Box {
            className = EconomicPerformanceStyles.SECTION_201_4_TABLE.cssClass
            TableHeader {
                header = "Type Of Assistance"
            }
            TableHeader {
                header = "Government / Agency"
            }
            TableHeader {
                header = "Monetary Value"
            }
            Label {
                label = "Tax relief and tax credits"
            }
            ESGTextAreaFieldWrapper {
                name = EconomicPerformanceField.SECTION_2014A1.name
                value = state.section2014A1
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2014B1.name
                value = state.section2014B1
                onChange = { event -> onChangeFunction(event, null, null) }
            }

            Label {
                label = "Subsidies"
            }
            ESGTextAreaFieldWrapper {
                name = EconomicPerformanceField.SECTION_2014A2.name
                value = state.section2014A2
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2014B2.name
                value = state.section2014B2
                onChange = { event -> onChangeFunction(event, null, null) }
            }

            Label {
                label = "Investment grants, research and development grants, and other relevant types of grants"
            }
            ESGTextAreaFieldWrapper {
                name = EconomicPerformanceField.SECTION_2014A3.name
                value = state.section2014A3
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2014B3.name
                value = state.section2014B3
                onChange = { event -> onChangeFunction(event, null, null) }
            }

            Label {
                label = "Awards"
            }
            ESGTextAreaFieldWrapper {
                name = EconomicPerformanceField.SECTION_2014A4.name
                value = state.section2014A4
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2014B4.name
                value = state.section2014B4
                onChange = { event -> onChangeFunction(event, null, null) }
            }

            Label {
                label = "Royalty holidays"
            }
            ESGTextAreaFieldWrapper {
                name = EconomicPerformanceField.SECTION_2014A5.name
                value = state.section2014A5
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2014B5.name
                value = state.section2014B5
                onChange = { event -> onChangeFunction(event, null, null) }
            }


            Label {
                label = "Financial assistance from Export Credit Agencies (ECAs)"
            }
            ESGTextAreaFieldWrapper {
                name = EconomicPerformanceField.SECTION_2014A6.name
                value = state.section2014A6
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2014B6.name
                value = state.section2014B6
                onChange = { event -> onChangeFunction(event, null, null) }
            }

            Label {
                label = "Financial incentives"
            }
            ESGTextAreaFieldWrapper {
                name = EconomicPerformanceField.SECTION_2014A7.name
                value = state.section2014A7
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2014B7.name
                value = state.section2014B7
                onChange = { event -> onChangeFunction(event, null, null) }
            }

            Label {
                label = "Other financial benefits received"
            }
            ESGTextAreaFieldWrapper {
                name = EconomicPerformanceField.SECTION_2014A8.name
                value = state.section2014A8
                onChange = { event -> onChangeFunction(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = EconomicPerformanceField.SECTION_2014B8.name
                value = state.section2014B8
                onChange = { event -> onChangeFunction(event, null, null) }
            }
        }
    }
    SectionSubHeading {
        subHeading = "Please list the countries in which your organization received financial assistance during the reporting period. For each country, indicate the total monetary value of assistance received."
    }
    Box {
        className = ESGReportInputStyles.TABLE_WRAPPER.cssClass
        Box {
            className = ESGReportInputStyles.SECTION_MAIN_LAYOUT.cssClass
            DynamicTableWrapper {
                addRows = {
                    props.addRow(EconomicPerformanceField.SECTION_2014_COUNTRIESANDVALUES.fieldName)
                    state.countriesAndValuesList.add(CountriesAndValues())
                    stateSetter(state)
                }
                items = state.countriesAndValuesList
                action = DynamicTableAction.ADD
                addRowBtnText = "country"
                Box {
                    className = EconomicPerformanceStyles.SECTION_201_4_TABLE_INNER.cssClass
                    TableHeader {
                        header = "Country"
                    }
                    TableHeader {
                        header ="Monetary Value"
                    }
                }
            }
            state.countriesAndValuesList.forEachIndexed{index, it ->
                DynamicTableWrapper {
                    deleteRows = {
                        props.deleteRow(EconomicPerformanceField.SECTION_2014_COUNTRIESANDVALUES.fieldName,index)
                        state.countriesAndValuesList.removeAt(index)
                        stateSetter(state)
                    }
                    items = state.countriesAndValuesList
                    action = DynamicTableAction.DELETE
                    Box {
                        key = "index$index"
                        className = EconomicPerformanceStyles.SECTION_201_4_TABLE_INNER.cssClass
                        ESGTextFieldWrapper {
                            name = EconomicPerformanceField.SECTION_2014PART2_A1.name
                            value = it.section2014Part2A1
                            onChange = { event ->
                                onChangeFunction(
                                    event,
                                    index,
                                    EconomicPerformanceField.SECTION_2014_COUNTRIESANDVALUES.fieldName
                                )
                            }
                        }
                        ESGNumberFieldWrapper {
                            name = EconomicPerformanceField.SECTION_2014PART2_B1.name
                            value = it.section2014Part2B1
                            onChange = { event ->
                                onChangeFunction(
                                    event,
                                    index,
                                    EconomicPerformanceField.SECTION_2014_COUNTRIESANDVALUES.fieldName
                                )
                            }
                        }
                    }
                }
            }
        }
    }

    Box {
        Label {
            label = "Does any government hold shares in your organization?"
        }
        ESGRadioButtonWrapper {
            name = EconomicPerformanceField.SECTION_2014PART2_FIELD1.name
            value = state.section2014Part2Field1
            onChange = { event, _ -> onChangeRadio(event) }
        }
    }
    if (state.section2014Part2Field1 == YesNoEnum.YES.description) {
        Box {
            className = ESGReportInputStyles.TABLE_WRAPPER.cssClass
            Box {
                className = MarketPresence.SECTION_202_2_TABLE.cssClass
                Label {
                    label = "The government(s) involved:"
                }
                ESGTextAreaFieldWrapper {
                    name = EconomicPerformanceField.SECTION_2014PART2_FIELD2.name
                    value = state.section2014Part2Field2
                    onChange = { event -> onChangeFunction(event, null, null) }
                }
                Label {
                    label = "The extent of the shareholding (percentage or value):"
                }
                ESGNumberFieldWrapper {
                    name = EconomicPerformanceField.SECTION_2014PART2_FIELD3.name
                    value = state.section2014Part2Field3
                    onChange = { event -> onChangeFunction(event, null, null) }
                }
            }
        }
    }

}