package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.components.npm.CalendarPicker
import com.ecosave.watch.portal.helpers.esg.validateCalendarDateEndState
import com.ecosave.watch.portal.helpers.esg.validateCalendarDateStartState
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.EsgReportValidationState
import js.core.jso
import moment.Moment
import moment.moment
import mui.icons.material.CalendarMonthOutlined
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.IconButton
import mui.material.InputAdornment
import mui.material.InputAdornmentPosition
import mui.material.InputBaseProps
import mui.material.Popover
import mui.material.TextField
import mui.system.sx
import muix.pickers.CalendarPickerView
import react.FC
import react.Props
import react.StateSetter
import react.create
import react.dom.aria.ariaDescribedBy
import react.useState
import web.cssom.px
import web.html.HTMLButtonElement
import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles as styles

external interface CalendarDateStartComponent : Props {
    var esgReportStateProps: EsgReportState
    var esgReportStateSetterProps: StateSetter<EsgReportState>
    var esgReportValidationStateProps: EsgReportValidationState
    var esgReportValidationStateSetterProps: StateSetter<EsgReportValidationState>
    var dateStart: Boolean
    var id: String
}

val CalendarDatePicker = FC<CalendarDateStartComponent> { props ->
    val esgReportState = props.esgReportStateProps
    val esgReportStateSetter = props.esgReportStateSetterProps
    val esgReportValidationState = props.esgReportValidationStateProps
    val esgReportValidationStateSetter = props.esgReportValidationStateSetterProps
    var startMonth by useState<Moment?>(null)
    var bAnchorEl: HTMLButtonElement? by useState(null)
    val openDateStart = bAnchorEl != null
    var startView by useState(CalendarPickerView.year)
    Box {
        TextField {
            className = styles.ESG_CALENDAR_TEXT_FONT.cssClass
            placeholder = "YYYY/MM/DD"
            id = props.id
            when (props.dateStart) {
                true -> {
                    value = esgReportState.reportFinancialYear.calendarDateStart
                    error = esgReportValidationState.reportFinancialYear.calendarDateStartErrorState
                    helperText =
                        esgReportValidationState.reportFinancialYear.calendarDateStartErrorMessage
                    onBlur = {
                        validateCalendarDateStartState(
                            esgReportState,
                            esgReportValidationState,
                            esgReportValidationStateSetter
                        )
                    }
                }

                false -> {
                    value = esgReportState.reportFinancialYear.calendarDateEnd
                    error = esgReportValidationState.reportFinancialYear.calendarDateEndErrorState
                    helperText = esgReportValidationState.reportFinancialYear.calendarDateEndErrorMessage
                    onBlur = {
                        validateCalendarDateEndState(
                            esgReportState,
                            esgReportValidationState,
                            esgReportValidationStateSetter
                        )
                    }
                }
            }
            asDynamic().InputProps = jso<InputBaseProps> {
                endAdornment = InputAdornment.create {
                    position = InputAdornmentPosition.end
                    IconButton {
                        ariaDescribedBy = "${props.id}-popover"
                        onClick = {
                            bAnchorEl = it.currentTarget
                        }
                        CalendarMonthOutlined()
                    }
                }
            }
        }
        Popover {
            id = "${props.id}-popover"
            open = openDateStart
            anchorEl = bAnchorEl
            onClose = { _, _ ->
                bAnchorEl = null
            }
            CalendarPicker {
                date = startMonth
                disableFuture = true
                onViewChange = {
                    startView = it
                }
                onYearChange = {
                    startView = CalendarPickerView.month
                    console.log(startView)
                }
                onChange = { date, _ ->
                    startMonth = moment(date)
                    val selectedYear = moment(date).year().toInt()
                    val target = moment(date).format("YYYY-MM-DD")

                    when (props.dateStart) {
                        true -> {
                            val endDate = (moment(target).add(1, "years").subtract(1, "days"))
                                .format("YYYY-MM-DD")

                            esgReportStateSetter(
                                esgReportState.copy(
                                    reportFinancialYear = esgReportState.reportFinancialYear.copy(
                                        calendarDateStart = target,
                                        calendarDateEnd = endDate,
                                        financialYear = if (selectedYear != esgReportState.reportFinancialYear.financialYear) selectedYear else esgReportState.reportFinancialYear.financialYear
                                    )
                                )

                            )
                        }

                        false -> {
                            esgReportStateSetter(
                                esgReportState.copy(
                                    reportFinancialYear = esgReportState.reportFinancialYear.copy(
                                        calendarDateEnd = target,
                                        financialYear = if (selectedYear != esgReportState.reportFinancialYear.financialYear) selectedYear else esgReportState.reportFinancialYear.financialYear

                                    )
                                )
                            )
                        }
                    }
                }
            }
            Button {
                sx {
                    margin = 10.px
                }
                variant = ButtonVariant.contained
                onClick = {
                    bAnchorEl = null
                }
                +"Done"
            }
        }
    }
}