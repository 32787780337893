package com.ecosave.watch.portal.helpers.common

import kotlinx.coroutines.*
import kotlin.math.roundToLong

fun formatValue(value: dynamic): String {
    return value.toLocaleString("en-US") as String
}

fun formatTick(value: String): String {
    if (value.length > 5 && value.all { char -> char.isDigit() }) {
        val intVal = value.toInt()
        return formatValue((intVal / 1000).asDynamic())
    }
    return formatValue(value.asDynamic())
}

fun removeDecimalAndAddComma(input: Double): String {
    return input.roundToLong().toInt().asDynamic().toLocaleString("en-US") as String
}

private var debounceJob: Job? = null
fun useDebounce(delayMillis: Long): (() -> Unit) -> Unit {

    return { action ->
        debounceJob?.cancel()
        debounceJob = CoroutineScope(Dispatchers.Default).launch {
            delay(delayMillis)
            action()
        }
    }
}
