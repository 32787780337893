package com.ecosave.watch.portal.components.esg.indirecteconomicimpacts

import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.IndirectEconomicImpactsField
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.*
import com.ecosave.watch.portal.services.esg.addEsgCollectionRow
import com.ecosave.watch.portal.services.esg.deleteEsgCollectionRow
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.system.Box
import react.FC
import react.Props
import react.StateSetter
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLTextAreaElement
import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles as styles


external interface IndirectEconomicImpactsComponentProps : Props {
    var esgReportStateProps: EsgReportState
    var esgReportStateSetterProps: StateSetter<EsgReportState>
    var setInputState: StateSetter<CurrentInputState>
}

val IndirectEconomicImpactsComponent = FC<IndirectEconomicImpactsComponentProps> { props ->

    val esgState = props.esgReportStateProps
    val esgStateSetter = props.esgReportStateSetterProps

    val addRowFunction: (String) -> Unit = {fieldName ->
        mainScope.launch {
            addEsgCollectionRow(
                EsgCollectionAddRow(
                    objectName = EsgSection.INDIRECT_ECONOMIC_IMPACTS.name,
                    reportFileName = esgState.reportFileName,
                    patchCollection = RowPatch(
                        pathFirst = fieldName
                    )
                )
            )
        }
    }

    val deleteRowFunction: (String, Int) -> Unit = {path,index ->
        mainScope.launch {
            deleteEsgCollectionRow(
                EsgCollectionDeleteRow(
                    objectPatchEnum= EsgSection.INDIRECT_ECONOMIC_IMPACTS,
                    reportFileName=esgState.reportFileName,
                    pathFirst= path,
                    indexAt = index
                )
            )
        }
    }

    esgState.report?.indirectEconomicImpacts?.let { state ->

        val stateSetter: (state: IndirectEconomicImpactsState) -> Unit = {
            esgStateSetter(
                esgState.copy(
                    report = esgState.report.copy(
                        indirectEconomicImpacts = state
                    )
                )
            )
        }

        val onChangeFunction: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            when (event.target) {
                is HTMLTextAreaElement -> {
                    val target = event.target as HTMLTextAreaElement
                    val propertyName: IndirectEconomicImpactsField = IndirectEconomicImpactsField.valueOf(target.name)
                    val propertyValue = target.value

                    if(field == IndirectEconomicImpactsField.INFRASTRUCTURE_INVESTMENT_LIST.fieldName  && changeIndex != null) {
                        state.infrastructureInvestmentList[changeIndex].section2031Part1A1 = if (propertyName == IndirectEconomicImpactsField.SECTION_2031PART1A1) propertyValue else state.infrastructureInvestmentList[changeIndex].section2031Part1A1
                        state.infrastructureInvestmentList[changeIndex].section2031Part1A2 = if (propertyName == IndirectEconomicImpactsField.SECTION_2031PART1A2) propertyValue else state.infrastructureInvestmentList[changeIndex].section2031Part1A2
                        state.infrastructureInvestmentList[changeIndex].section2031Part1A3 = if (propertyName == IndirectEconomicImpactsField.SECTION_2031PART1A3) propertyValue else state.infrastructureInvestmentList[changeIndex].section2031Part1A3
                    }
                    if(field == IndirectEconomicImpactsField.SERVICE_SUPPORTED_LIST.fieldName  && changeIndex != null) {
                        state.servicesSupportedList[changeIndex].section2031Part2A1 = if (propertyName == IndirectEconomicImpactsField.SECTION_2031PART2A1) propertyValue else state.servicesSupportedList[changeIndex].section2031Part2A1
                        state.servicesSupportedList[changeIndex].section2031Part2A2 = if (propertyName == IndirectEconomicImpactsField.SECTION_2031PART2A2) propertyValue else state.servicesSupportedList[changeIndex].section2031Part2A2
                        state.servicesSupportedList[changeIndex].section2031Part2A3 = if (propertyName == IndirectEconomicImpactsField.SECTION_2031PART2A3) propertyValue else state.servicesSupportedList[changeIndex].section2031Part2A3
                    }
                    if(field == IndirectEconomicImpactsField.INDIRECT_ECONOMIC_IMPACT_LIST.fieldName  && changeIndex != null) {
                        state.indirectEconomicImpactList[changeIndex].section2032A1 = if (propertyName == IndirectEconomicImpactsField.SECTION_2032A1) propertyValue else state.indirectEconomicImpactList[changeIndex].section2032A1
                        state.indirectEconomicImpactList[changeIndex].section2032B1 = if (propertyName == IndirectEconomicImpactsField.SECTION_2032B1) propertyValue else state.indirectEconomicImpactList[changeIndex].section2032B1
                    }
                    stateSetter(state)
                    props.setInputState(
                        CurrentInputState(
                            parentPath = field,
                            childPath = propertyName.fieldName,
                            index = changeIndex,
                            value = JsonPrimitive(propertyValue),
                            objectName = EsgSection.INDIRECT_ECONOMIC_IMPACTS.name,
                            reportName = esgState.reportFileName
                        )
                    )
                }
            }
        }


        Box {
            className = styles.SECTION_MAIN_LAYOUT.cssClass

            Section203_1 {
                this.state = state
                this.stateSetter = stateSetter
                onChange = onChangeFunction
                addRow = addRowFunction
                deleteRow = deleteRowFunction
            }

            Section203_2 {
                this.state = state
                this.stateSetter = stateSetter
                onChange = onChangeFunction
                addRow = addRowFunction
                deleteRow = deleteRowFunction
            }
        }
    }
}