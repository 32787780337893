package com.ecosave.watch.portal.components.esg.procurementpractices

import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.ProcurementPracticesField
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.ProcurementPracticesState
import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.Props
import react.StateSetter
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement
import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles as styles


external interface ProcurementPracticesComponentProps : Props {
    var esgReportStateProps: EsgReportState
    var esgReportStateSetterProps: StateSetter<EsgReportState>
    var setInputState: StateSetter<CurrentInputState>
}


val ProcurementPracticesComponent = FC<ProcurementPracticesComponentProps> { props ->

    val esgState = props.esgReportStateProps
    val esgStateSetter = props.esgReportStateSetterProps

    esgState.report?.procurementPractices?.let { state ->
        fun stateSetter(state: ProcurementPracticesState) {
            esgStateSetter(
                esgState.copy(
                    report = esgState.report.copy(
                        procurementPractices = state
                    )
                )
            )
        }

        val onChangeNumber: (FormEvent<HTMLDivElement>) -> Unit = { event ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = ProcurementPracticesField.valueOf(target.name)
                val propertyValue = target.value.toDoubleOrNull()
                stateSetter(
                    state.copy(
                        section2041A1 = if (propertyName == ProcurementPracticesField.SECTION_204_1_A1) propertyValue else state.section2041A1
                    )
                )
                props.setInputState(
                    CurrentInputState(
                        childPath = propertyName.fieldName,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSection.PROCUREMENT_PRACTICES.name,
                        reportName = esgState.reportFileName
                    )
                )
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit = { event ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = ProcurementPracticesField.valueOf(target.name)
            val propertyValue = target.value
            stateSetter(
                state.copy(
                    section2041A2 = if (propertyName == ProcurementPracticesField.SECTION_204_1_A2) propertyValue else state.section2041A2,
                    section2041A3 = if (propertyName == ProcurementPracticesField.SECTION_204_1_A3) propertyValue else state.section2041A3
                )
            )
            props.setInputState(
                CurrentInputState(
                    childPath = propertyName.fieldName,
                    value = JsonPrimitive(propertyValue.trim()),
                    objectName = EsgSection.PROCUREMENT_PRACTICES.name,
                    reportName = esgState.reportFileName
                )
            )
        }

        Box {
            className = styles.SECTION_MAIN_LAYOUT.cssClass
            SectionMainTitle {
                title = "204-1 PROPORTION OF SPENDING ON LOCAL SUPPLIERS"
            }
            Box {
                className = ESGReportInputStyles.TABLE_WRAPPER.cssClass
                Box {
                    className = ESGReportInputStyles.SECTION_204_1_TABLE.cssClass
                    Label {
                        label =
                            "Percentage of the procurement budget used for significant locations of operation that is spent on suppliers local to that operation (i.e. percentage of products and services purchased locally)"
                    }
                    ESGNumberFieldWrapper {
                        name = ProcurementPracticesField.SECTION_204_1_A1.name
                        value = state.section2041A1
                        onChange = { event -> onChangeNumber(event) }
                    }
                    Label {
                        label = "The organization’s geographical definition of ‘local’."
                    }
                    ESGTextAreaFieldWrapper {
                        name = ProcurementPracticesField.SECTION_204_1_A2.name
                        value = state.section2041A2
                        onChange = { event -> onChangeTextArea(event) }
                    }
                    Label {
                        label = "The definition used for ‘significant locations of operation’."
                    }
                    ESGTextAreaFieldWrapper {
                        name = ProcurementPracticesField.SECTION_204_1_A3.name
                        value = state.section2041A3
                        onChange = { event -> onChangeTextArea(event) }
                    }
                }
            }
        }
    }
}

