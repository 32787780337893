package com.ecosave.watch.portal.components.esg.customerhealthnsafety

import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.common.isValidInteger
import com.ecosave.watch.portal.helpers.esg.*
import com.ecosave.watch.portal.helpers.esg.CustomerHealthNSafetyFeildsEnum as FieldEnum
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.CustomerHealthAndSafetyState
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.Props
import react.StateSetter
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

external interface CustomerHealthAndSafetyComponentProps : Props {
    var esgReportStateProps: EsgReportState
    var esgReportStateSetterProps: StateSetter<EsgReportState>
    var setInputState: StateSetter<CurrentInputState>
    var setNotificationMessage: StateSetter<String>
    var setNotificationStatus: StateSetter<NotificationStatus>
    var setOpenAlertNotifications: StateSetter<Boolean>
}

val CustomerHealthAndSafetyComponent = FC<CustomerHealthAndSafetyComponentProps> { props ->
    val esgState = props.esgReportStateProps
    val esgStateSetter = props.esgReportStateSetterProps

    esgState.report?.customerHealthAndSafety?.let { state ->
        fun stateSetter(state: CustomerHealthAndSafetyState) {
            esgStateSetter(
                esgState.copy(
                    report = esgState.report.copy(
                        customerHealthAndSafety = state
                    )
                )
            )
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit = { event ->
            resetPreviousPatchState(props.setInputState)
            var status: ApiCallStatus?
            val target = event.target as HTMLTextAreaElement
            val propertyName = FieldEnum.valueOf(target.name)
            val propertyValue = target.value.ifBlank { null }

            stateSetter(
                state.copy(
                    section4161A1 = if (propertyName == FieldEnum.SECTION_416_1_A1) {
                        propertyValue
                    } else state.section4161A1,
                    section4162Field1 = if (propertyName == FieldEnum.SECTION_416_2_FIELD1) {
                        propertyValue
                    } else state.section4162Field1
                )
            )
            mainScope.launch {
                status = autoSaveEsgField(
                    propertyName.fieldName,
                    JsonPrimitive(propertyValue),
                    EsgSection.CUSTOMER_HEALTH_AND_SAFETY.name,
                    esgState.reportFileName
                )
                savingErrorMessage(
                    props.setNotificationMessage,
                    props.setNotificationStatus,
                    props.setOpenAlertNotifications,
                    status
                )
            }
        }

        val onChangeNumber: (FormEvent<HTMLDivElement>) -> Unit = { event ->
            resetPreviousPatchState(props.setInputState)
            var status: ApiCallStatus?
            val target = event.target as HTMLInputElement
            val propertyName = FieldEnum.valueOf(target.name)
            if (isValidInteger(target)) {
                val propertyValue = target.value.toIntOrNull()

                stateSetter(
                    state.copy(
                        section4162Current1 = if (propertyName == FieldEnum.SECTION_416_2_CURRENT1) {
                            propertyValue
                        } else state.section4162Current1,
                        section4162Previous1 = if (propertyName == FieldEnum.SECTION_416_2_PREVIOUS1) {
                            propertyValue
                        } else state.section4162Previous1,
                        section4162Current2 = if (propertyName == FieldEnum.SECTION_416_2_CURRENT2) {
                            propertyValue
                        } else state.section4162Current2,
                        section4162Previous2 = if (propertyName == FieldEnum.SECTION_416_2_PREVIOUS2) {
                            propertyValue
                        } else state.section4162Previous2,
                        section4162Current3 = if (propertyName == FieldEnum.SECTION_416_2_CURRENT3) {
                            propertyValue
                        } else state.section4162Current3,
                        section4162Previous3 = if (propertyName == FieldEnum.SECTION_416_2_PREVIOUS3) {
                            propertyValue
                        } else state.section4162Previous3,
                        section4162Current4 = if (propertyName == FieldEnum.SECTION_416_2_CURRENT4) {
                            propertyValue
                        } else state.section4162Current4,
                        section4162Previous4 = if (propertyName == FieldEnum.SECTION_416_2_PREVIOUS4) {
                            propertyValue
                        } else state.section4162Previous4,
                    )
                )
                mainScope.launch {
                    status = autoSaveEsgField(
                        path = propertyName.fieldName,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSection.CUSTOMER_HEALTH_AND_SAFETY.name,
                        reportName = esgState.reportFileName
                    )
                    savingErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications,
                        status
                    )
                }
            }
        }

        Box {
            className = ESGReportInputStyles.SECTION_MAIN_LAYOUT.cssClass
            Section416 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
                this.onChangeNumber = onChangeNumber
            }
        }

    }

}