package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class Section2012Risk(
    var section2012RiskA1: String? = null,
    var section2012RiskA2: String? = null,
    var section2012RiskA3: String? = null,
    var section2012RiskA4: String? = null,
    var section2012RiskA5: Double? = null,
)

@Serializable
data class Section2012Opportunity(
    var section2012OpportunityA1: String? = null,
    var section2012OpportunityA2: String? = null,
    var section2012OpportunityA3: String? = null,
    var section2012OpportunityA4: String? = null,
    var section2012OpportunityA5: Double? = null,
)

@Serializable
data class RetirementPlans(
    var section2013A10: String? = null,
    var section2013A11: Double? = null,
    var section2013A12: Double? = null,
    var section2013A13: String? = null,
    var section2013A14: String? = null,
    var section2013A15: String? = null,
)

@Serializable
data class CountriesAndValues(
    var section2014Part2A1: String? = null,
    var section2014Part2B1: Double? = null,
)

@Serializable
data class EconomicPerformanceState(
    var section2011A1: Double? = null,
    var section2011A2: Double? = null,
    var section2011A3: Double? = null,
    var section2011A4: Double? = null,
    var section2011A5: Double? = null,
    var section2011A6: Double? = null,
    var section2011A7: Double? = null,
    var section2011A8: Double? = null,
    var section2011A9: Double? = null,
    var section2011Field1: String? = null,
    var section2011Field2: String? = null,

    var section2011B1: Double? = null,
    var section2011B2: Double? = null,
    var section2011B3: Double? = null,
    var section2011B4: Double? = null,
    var section2011B5: Double? = null,
    var section2011B6: Double? = null,
    var section2011B7: Double? = null,
    var section2011B8: Double? = null,
    var section2011B9: Double? = null,

    var financialRisksList: MutableList<Section2012Risk> = MutableList(1){Section2012Risk()},
    var financialOpportunitiesList:  MutableList<Section2012Opportunity> = MutableList(1){Section2012Opportunity()},

    var section2012Field1: String? = null,

    var section2013A1: Double? = null,
    var section2013A2: String? = null,
    var section2013A3: Double? = null,
    var section2013A4: Double? = null,
    var section2013A5: String? = null,
    var section2013A6: String? = null,
    var section2013A7: String? = null,
    var section2013A8: String? = null,
    var section2013A9: String? = null,
    var retirementPlansList: MutableList<RetirementPlans> = MutableList(1){RetirementPlans()},


    var section2014A1: String? = null,
    var section2014A2: String? = null,
    var section2014A3: String? = null,
    var section2014A4: String? = null,
    var section2014A5: String? = null,
    var section2014A6: String? = null,
    var section2014A7: String? = null,
    var section2014A8: String? = null,

    var section2014B1: Double? = null,
    var section2014B2: Double? = null,
    var section2014B3: Double? = null,
    var section2014B4: Double? = null,
    var section2014B5: Double? = null,
    var section2014B6: Double? = null,
    var section2014B7: Double? = null,
    var section2014B8: Double? = null,

    var countriesAndValuesList: MutableList<CountriesAndValues> =  MutableList(1){CountriesAndValues()},

    var section2014Part2Field1: String? = null,
    var section2014Part2Field2: String? = null,
    var section2014Part2Field3: Double? = null
)
