package com.ecosave.watch.portal.components.facilitymanagement

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.DialogWrapper
import com.ecosave.watch.portal.components.common.Loading
import com.ecosave.watch.portal.helpers.billing.BillingConstants
import com.ecosave.watch.portal.helpers.common.ColorConstants
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.isValidLongNumber
import com.ecosave.watch.portal.helpers.common.showNotification
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.helpers.facilitymanagement.EnergyStarConnectedAccountsFetchingStatus
import com.ecosave.watch.portal.helpers.facilitymanagement.FacilityConstants
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.services.facilitymanagement.connectToEnergyStar
import com.ecosave.watch.portal.services.facilitymanagement.getEnergyStarConnectedAccounts
import emotion.react.css
import io.ktor.http.*
import kotlinx.coroutines.launch
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.FormControlVariant
import mui.material.Grid
import mui.material.Link
import mui.material.Size
import mui.material.TextField
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.ChildrenBuilder
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.ul
import react.dom.onChange
import react.useEffect
import react.useState
import web.cssom.Color
import web.cssom.JustifyContent
import web.cssom.px
import web.html.HTMLInputElement
import web.html.InputType
import web.window.WindowTarget

external interface ConnectEnergyStarProps : Props {
    var openConnectEnergyStarForm: Boolean
    var setOpenConnectEnergyStarForm: StateSetter<Boolean>
}

val ConnectEnergyStar = FC<ConnectEnergyStarProps> { props ->

    var isSaving by useState(false)
    var energyStarAccountId by useState<Long?>(null)
    val (notificationState, setNotificationState) = useState(NotificationState())
    var energyStarConnectedAccounts by useState<List<Long>>(emptyList())
    var energyStarConnectedAccountsFetchingStatus by useState(EnergyStarConnectedAccountsFetchingStatus.LOADING)

    fun closeConnectEnergyStarForm() {
        energyStarAccountId = null
        props.setOpenConnectEnergyStarForm(false)
        energyStarConnectedAccountsFetchingStatus = EnergyStarConnectedAccountsFetchingStatus.LOADING
    }

    useEffect(props.openConnectEnergyStarForm) {
        if (props.openConnectEnergyStarForm) {
            mainScope.launch {
                val accounts = getEnergyStarConnectedAccounts()
                if (accounts == null) {
                    energyStarConnectedAccountsFetchingStatus = EnergyStarConnectedAccountsFetchingStatus.ERROR
                } else {
                    energyStarConnectedAccountsFetchingStatus = EnergyStarConnectedAccountsFetchingStatus.SUCCESS
                    energyStarConnectedAccounts = accounts
                }
            }
        }
    }


    DialogWrapper {
        open = props.openConnectEnergyStarForm
        dialogMaxWidth = 900
        dialogTitle = "Connect To ${BillingConstants.ENERGY_STAR}"
        DialogContent {
            sx {
                margin = 20.px
            }
            when (energyStarConnectedAccountsFetchingStatus) {

                EnergyStarConnectedAccountsFetchingStatus.LOADING -> Loading()
                EnergyStarConnectedAccountsFetchingStatus.ERROR -> {
                    Typography {
                        variant = TypographyVariant.h6
                        +"We are having problems with ${BillingConstants.ENERGY_STAR} right now. Please try later."
                    }
                }

                EnergyStarConnectedAccountsFetchingStatus.SUCCESS -> {
                    Grid {
                        container = true
                        spacing = responsive(3)

                        Grid {
                            item = true
                            xs = 12
                            if (energyStarConnectedAccounts.isEmpty()) {
                                Typography {
                                    variant = TypographyVariant.h6
                                    sx {
                                        marginBottom = 20.px
                                    }
                                    +"As of now, you do not have any connected ${BillingConstants.ENERGY_STAR} account with us."
                                }
                                connectEnergyStarInstructions()
                            } else {
                                Typography {
                                    variant = TypographyVariant.h6
                                    +"${BillingConstants.ENERGY_STAR} connected accounts."
                                }
                                ul {
                                    energyStarConnectedAccounts.forEach {
                                        li {
                                            +it.toString()
                                        }
                                    }
                                }
                                connectEnergyStarInstructions()
                            }
                        }

                        Grid {
                            item = true
                            xs = 12
                            TextField {
                                variant = FormControlVariant.outlined
                                size = Size.medium
                                fullWidth = true
                                label = ReactNode("Enter ${BillingConstants.ENERGY_STAR} Account Id *")
                                value = energyStarAccountId
                                type = InputType.number
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    if (isValidLongNumber(target)) {
                                        energyStarAccountId = target.value.toLongOrNull()
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        DialogActions {
            sx {
                justifyContent = JustifyContent.center
            }
            Button {
                +"Cancel"
                onClick = {
                    closeConnectEnergyStarForm()
                }
            }
            if (energyStarConnectedAccountsFetchingStatus == EnergyStarConnectedAccountsFetchingStatus.SUCCESS) {
                Button {
                    if (isSaving) +"Accepting Connection..." else +"Accept Connection"
                    disabled = isSaving || energyStarAccountId == null
                    variant = ButtonVariant.contained
                    onClick = {
                        mainScope.launch {
                            isSaving = true
                            val response = connectToEnergyStar(energyStarAccountId!!)
                            when (response) {
                                null -> {
                                    showNotification(
                                        "There has been an issue connecting with ${BillingConstants.ENERGY_STAR}, please retry.",
                                        NotificationStatus.ERROR,
                                        notificationState,
                                        setNotificationState
                                    )
                                }

                                HttpStatusCode.Created -> {
                                    showNotification(
                                        "Your ${BillingConstants.ENERGY_STAR} account is connected.",
                                        NotificationStatus.SUCCESS,
                                        notificationState,
                                        setNotificationState
                                    )
                                    closeConnectEnergyStarForm()
                                }

                                HttpStatusCode.BadRequest -> {
                                    showNotification(
                                        "We can't see any connection request for this account.",
                                        NotificationStatus.ERROR,
                                        notificationState,
                                        setNotificationState
                                    )
                                }

                                HttpStatusCode.Found -> {
                                    showNotification(
                                        "${BillingConstants.ENERGY_STAR} account ID already exists in our system.",
                                        NotificationStatus.INFO,
                                        notificationState,
                                        setNotificationState
                                    )
                                }
                            }
                            isSaving = false
                        }
                    }
                }
            }
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}

fun ChildrenBuilder.connectEnergyStarInstructions() {
    Typography {
        variant = TypographyVariant.body1
        +"Follow the instructions below to connect your ${BillingConstants.ENERGY_STAR} account."
    }
    ul {
        li {
            +"Login to your "
            Link {
                target = WindowTarget._blank
                href = "https://portfoliomanager.energystar.gov/pm/login"
                +FacilityConstants.ENERGY_STAR_PORTFOLIO_MANAGER
            }
            +" account."
        }
        li {
            +"While in ${FacilityConstants.ENERGY_STAR_PORTFOLIO_MANAGER} go to "
            span {
                css {
                    color = Color(ColorConstants.HIGHLIGHTED_TEXT)
                }
                +"Contacts"
            }
            +", then "
            span {
                css {
                    color = Color(ColorConstants.HIGHLIGHTED_TEXT)
                }
                +" Add New Contacts "
            }
            +"and search the email address "
            span {
                css {
                    color = Color(ColorConstants.HIGHLIGHTED_TEXT)
                }
                +"energystar@ecosaveinc.com"
            }
            +" and click"
            span {
                css {
                    color = Color(ColorConstants.HIGHLIGHTED_TEXT)
                }
                +" Connect."
            }
        }
        li {
            +"Copy your ${BillingConstants.ENERGY_STAR} account ID (A numeric id available under your account settings)."
        }
        li {
            +"Come back to this screen and enter your ${BillingConstants.ENERGY_STAR} account id below."
        }
        li {
            +"Finally, click on "
            span {
                css {
                    color = Color(ColorConstants.HIGHLIGHTED_TEXT)
                }
                +"Accept Connection "
            }
            +"to complete your connection request."
        }
    }
}
