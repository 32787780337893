package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.DialogSpinner
import com.ecosave.watch.portal.helpers.common.OperationType
import com.ecosave.watch.portal.helpers.common.handleNotificationStatus
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.EsgSubSection
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.helpers.esg.esgPatch
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.StakeholderEngagementState
import com.ecosave.watch.portal.services.esg.esgDownloadReportSection
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControlVariant
import mui.material.TextField
import mui.system.Box
import react.FC
import react.Props
import react.StateSetter
import react.dom.html.ReactHTML.label
import react.dom.onChange
import react.useState
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement
import web.html.InputType
import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles as styles


external interface StakeholderEngagementComponentProps : Props {
    var esgReportStateProps: EsgReportState
    var esgReportStateSetterProps: StateSetter<EsgReportState>
}

val StakeholderEngagementComponent = FC<StakeholderEngagementComponentProps> { props ->
    var isLoading by useState(false)
    var esgReportState = props.esgReportStateProps
    val (notificationState, notificationStateSetter) = useState(NotificationState())

    esgReportState.report?.stakeholderEngagement?.let { Sge ->

        var stakeholderEngagementState = Sge
        var esgReportStateSetter = props.esgReportStateSetterProps
        fun setStakeholderEngagementState(newStakeholderEngagementState: StakeholderEngagementState) {
            esgReportStateSetter(
                esgReportState.copy(
                    report = esgReportState.report?.copy(
                        stakeholderEngagement = newStakeholderEngagementState
                    )
                )
            )
        }
        Box {
            className = styles.MAIN_ESG_FORM.cssClass
            Box {
                Button {
                    className = styles.ESG_PREVIEW.cssClass
                    variant = ButtonVariant.contained
                    onClick = {
                        isLoading = true
                        mainScope.launch {
                            val status = esgDownloadReportSection(
                                props.esgReportStateProps.reportFileName,
                                EsgSection.GENERAL_DISCLOSURES

                            )
                            isLoading = false
                            handleNotificationStatus(
                                status,
                                notificationState,
                                notificationStateSetter,
                                OperationType.DOWNLOAD
                            )
                        }
                    }
                    +"Preview"
                }
            }
            Box {
                className = styles.MAIN_ESG_FORM.cssClass
                Box {
                    Button {
                        className = styles.ESG_PREVIEW.cssClass
                        variant = ButtonVariant.contained
                        onClick = {
                            isLoading = true
                            mainScope.launch {
                                val status = esgDownloadReportSection(
                                    props.esgReportStateProps.reportFileName,
                                    EsgSection.GENERAL_DISCLOSURES
                                )
                                isLoading = false
                                handleNotificationStatus(
                                    status,
                                    notificationState,
                                    notificationStateSetter,
                                    OperationType.DOWNLOAD
                                )

                            }
                        }
                        +"Preview"
                    }
                }

                Box {
                    label {
                        className = styles.ESG_FIRST_FONT.cssClass
                        +"GRI 2-29 Approach to stakeholder engagement"
                    }
                }
                Box {
                    label {
                        className = styles.ESG_SECOND_FONT.cssClass
                        +"""
                    The organization’s approach to engaging with stakeholders
                    Include how the organization seeks to ensure meaningful engagement with stakeholders
                """
                    }
                }
                Box {
                    className = styles.ESG_THREE_COLUMNS.cssClass

                    Box {
                        label {
                            className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                            +"Category of stakeholder"
                        }
                    }
                    Box {
                        label {
                            className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                            +"How they are identified"
                        }
                    }
                    Box {
                        label {
                            className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                            +"Purpose of the engagement"
                        }
                    }
                    Box {
                        TextField {
                            className = styles.ESG_INPUT_ITEM.cssClass

                            type = InputType.text
                            variant = FormControlVariant.outlined
                            value = stakeholderEngagementState.section229A1
                            onChange = {
                                val target = it.target as HTMLInputElement
                                setStakeholderEngagementState(
                                    stakeholderEngagementState.copy(
                                        section229A1 = target.value
                                    )
                                )
                                esgPatch(
                                    "section229A1",
                                    JsonPrimitive(target.value),
                                    EsgSubSection.STAKEHOLDER_ENGAGEMENT.name,
                                    esgReportState.reportFileName
                                )
                            }
                        }
                    }
                    Box {
                        TextField {
                            className = styles.ESG_INPUT_ITEM.cssClass

                            type = InputType.text
                            variant = FormControlVariant.outlined
                            value = stakeholderEngagementState.section229B1
                            onChange = {
                                val target = it.target as HTMLInputElement
                                setStakeholderEngagementState(
                                    stakeholderEngagementState.copy(
                                        section229B1 = target.value
                                    )
                                )
                                esgPatch(
                                    "section229B1",
                                    JsonPrimitive(target.value),
                                    EsgSubSection.STAKEHOLDER_ENGAGEMENT.name,
                                    esgReportState.reportFileName
                                )
                            }
                        }
                    }
                    Box {
                        TextField {
                            className = styles.ESG_INPUT_ITEM.cssClass

                            type = InputType.text
                            variant = FormControlVariant.outlined
                            value = stakeholderEngagementState.section229C1
                            onChange = {
                                val target = it.target as HTMLInputElement
                                setStakeholderEngagementState(
                                    stakeholderEngagementState.copy(
                                        section229C1 = target.value
                                    )
                                )
                                esgPatch(
                                    "section229C1",
                                    JsonPrimitive(target.value),
                                    EsgSubSection.STAKEHOLDER_ENGAGEMENT.name,
                                    esgReportState.reportFileName
                                )
                            }
                        }
                    }
                }
                Box {
                    label {
                        className = styles.ESG_FOURTH_FONT.cssClass
                        +"How the organization seeks to ensure meaningful engagement with stakeholders?"
                    }
                    TextField {
                        className = styles.ESG_INPUT_ITEM.cssClass

                        type = InputType.text
                        variant = FormControlVariant.outlined
                        value = stakeholderEngagementState.section229Field1
                        multiline = true
                        minRows = ReportConstants.TEXT_AREA_MIN_ROWS
                        maxRows = ReportConstants.TEXT_AREA_MAX_ROWS

                        onChange = {
                            val target = it.target as HTMLTextAreaElement
                            setStakeholderEngagementState(
                                stakeholderEngagementState.copy(
                                    section229Field1 = target.value
                                )
                            )
                            esgPatch(
                                "section229Field1",
                                JsonPrimitive(target.value),
                                EsgSubSection.ACTIVITIES_AND_WORKERS.name,
                                esgReportState.reportFileName
                            )
                        }
                    }
                }
                Box {
                    label {
                        className = styles.ESG_FIRST_FONT.cssClass
                        +"GRI 2-30 Collective Bargaining Agreements"
                    }
                }
                Box {
                    className = styles.ESG_THREE_COLUMNS.cssClass
                    Box {
                        label {
                            className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                            +""
                        }
                    }
                    Box {
                        label {
                            className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                            +"Current Year"
                        }
                    }
                    Box {
                        label {
                            className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                            +"Previous Year(s)"
                        }
                    }
                    Box {
                        label {
                            className = styles.ESG_FOURTH_FONT.cssClass
                            +"Total Employees"
                        }
                    }
                    Box {
                        TextField {
                            className = styles.ESG_INPUT_ITEM.cssClass

                            type = InputType.number
                            variant = FormControlVariant.outlined
                            value = stakeholderEngagementState.section230A1
                            onChange = {
                                val target = it.target as HTMLInputElement
                                setStakeholderEngagementState(
                                    stakeholderEngagementState.copy(
                                        section230A1 = target.value.toIntOrNull() ?: 0
                                    )
                                )
                                esgPatch(
                                    "section230A1",
                                    JsonPrimitive(target.value.toIntOrNull() ?: 0),
                                    EsgSubSection.STAKEHOLDER_ENGAGEMENT.name,
                                    esgReportState.reportFileName
                                )
                            }
                        }
                    }
                    Box {
                        TextField {
                            className = styles.ESG_INPUT_ITEM.cssClass

                            type = InputType.number
                            variant = FormControlVariant.outlined
                            value = stakeholderEngagementState.section230B1
                            onChange = {
                                val target = it.target as HTMLInputElement
                                setStakeholderEngagementState(
                                    stakeholderEngagementState.copy(
                                        section230B1 = target.value.toIntOrNull() ?: 0
                                    )
                                )
                                esgPatch(
                                    "section230B1",
                                    JsonPrimitive(target.value.toIntOrNull() ?: 0),
                                    EsgSubSection.STAKEHOLDER_ENGAGEMENT.name,
                                    esgReportState.reportFileName
                                )
                            }
                        }
                    }
                    Box {
                        label {
                            className = styles.ESG_FOURTH_FONT.cssClass
                            +"Employees covered by collective bargaining agreements"
                        }
                    }
                    Box {
                        TextField {
                            className = styles.ESG_INPUT_ITEM.cssClass

                            type = InputType.number
                            variant = FormControlVariant.outlined
                            value = stakeholderEngagementState.section230A2
                            onChange = {
                                val target = it.target as HTMLInputElement
                                setStakeholderEngagementState(
                                    stakeholderEngagementState.copy(
                                        section230A2 = target.value.toIntOrNull() ?: 0
                                    )
                                )
                                esgPatch(
                                    "section230A2",
                                    JsonPrimitive(target.value.toIntOrNull() ?: 0),
                                    EsgSubSection.STAKEHOLDER_ENGAGEMENT.name,
                                    esgReportState.reportFileName
                                )
                            }
                        }
                    }
                    Box {
                        TextField {
                            className = styles.ESG_INPUT_ITEM.cssClass

                            type = InputType.number
                            variant = FormControlVariant.outlined
                            value = stakeholderEngagementState.section230B2
                            onChange = {
                                val target = it.target as HTMLInputElement
                                setStakeholderEngagementState(
                                    stakeholderEngagementState.copy(
                                        section230B2 = target.value.toIntOrNull() ?: 0
                                    )
                                )
                                esgPatch(
                                    "section230B2",
                                    JsonPrimitive(target.value.toIntOrNull() ?: 0),
                                    EsgSubSection.STAKEHOLDER_ENGAGEMENT.name,
                                    esgReportState.reportFileName
                                )
                            }
                        }
                    }

                    Box {
                        label {
                            className = styles.ESG_FOURTH_FONT.cssClass
                            +"Percent of total employees covered by CBA’s"
                        }
                    }
                    Box {
                        TextField {
                            className = styles.ESG_INPUT_ITEM.cssClass

                            type = InputType.number
                            variant = FormControlVariant.outlined
                            value = stakeholderEngagementState.section230A3
                            onChange = {
                                val target = it.target as HTMLInputElement
                                setStakeholderEngagementState(
                                    stakeholderEngagementState.copy(
                                        section230A3 = target.value.toDoubleOrNull() ?: 0.0
                                    )
                                )
                                esgPatch(
                                    "section230A3",
                                    JsonPrimitive(target.value.toDoubleOrNull() ?: 0.0),
                                    EsgSubSection.STAKEHOLDER_ENGAGEMENT.name,
                                    esgReportState.reportFileName
                                )
                            }
                        }
                    }
                    Box {
                        TextField {
                            className = styles.ESG_INPUT_ITEM.cssClass

                            type = InputType.number
                            variant = FormControlVariant.outlined
                            value = stakeholderEngagementState.section230B3
                            onChange = {
                                val target = it.target as HTMLInputElement
                                setStakeholderEngagementState(
                                    stakeholderEngagementState.copy(
                                        section230B3 = target.value.toDoubleOrNull() ?: 0.0
                                    )
                                )
                                esgPatch(
                                    "section230B3",
                                    JsonPrimitive(target.value.toDoubleOrNull() ?: 0.0),
                                    EsgSubSection.STAKEHOLDER_ENGAGEMENT.name,
                                    esgReportState.reportFileName
                                )
                            }
                        }
                    }
                }
                Box {
                    label {
                        className = styles.ESG_FOURTH_FONT.cssClass
                        +"For employees not covered by collective bargaining agreements, report whether the organization determines their working conditions and terms of employment based on collective bargaining agreements that cover its other employees or based on collective bargaining agreements from other organizations."
                    }
                    TextField {
                        className = styles.ESG_INPUT_ITEM.cssClass

                        type = InputType.text
                        variant = FormControlVariant.outlined
                        value = stakeholderEngagementState.section230Field1
                        multiline = true
                        minRows = ReportConstants.TEXT_AREA_MIN_ROWS
                        maxRows = ReportConstants.TEXT_AREA_MAX_ROWS

                        onChange = {
                            val target = it.target as HTMLTextAreaElement
                            setStakeholderEngagementState(
                                stakeholderEngagementState.copy(
                                    section230Field1 = target.value
                                )
                            )
                            esgPatch(
                                "section230Field1",
                                JsonPrimitive(target.value),
                                EsgSubSection.ACTIVITIES_AND_WORKERS.name,
                                esgReportState.reportFileName
                            )
                        }
                    }
                }

                DialogSpinner {
                    open = isLoading
                    message = "Downloading..."
                }
                AlertNotifications {
                    open = notificationState.visible
                    status = notificationState.status
                    message = notificationState.message
                    closeNotification = {
                        notificationStateSetter(
                            notificationState.copy(
                                visible = false
                            )
                        )
                    }
                }
            }
        }
    }
}