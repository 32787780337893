package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.SectionSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.ActivitiesAndWorkersField
import com.ecosave.watch.portal.models.esg.ActivitiesAndWorkersState
import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles
import com.ecosave.watch.portal.styles.esg.GeneralDisclosuresStyles
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement

external interface Section27Props : Props {
    var onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit
    var state: ActivitiesAndWorkersState
    var onChangeNumber: (FormEvent<HTMLDivElement>) -> Unit
}

val Section2_7 = FC<Section27Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onChangeNumber = props.onChangeNumber
    val state = props.state

    SectionMainTitle {
        title = "2-7 EMPLOYEES"
    }

    Box {
        className = ESGReportInputStyles.TABLE_WRAPPER.cssClass
        Box {
            className = GeneralDisclosuresStyles.SECTION_2_7_TABLE.cssClass
            TableHeader {
                header = "Type Of Employee"
            }
            TableHeader {
                header = "Current Year"
            }
            TableHeader {
                header = "Previous Year(s)"
            }

            SectionSubHeading {
                subHeading = "Total Employees"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A1.name
                value = state.section27A1
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B1.name
                value = state.section27B1
                onChange = { event -> onChangeNumber(event) }
            }
            Label {
                label = "Male"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A2.name
                value = state.section27A2
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B2.name
                value = state.section27B2
                onChange = { event -> onChangeNumber(event) }
            }
            Label {
                label = "Female"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A3.name
                value = state.section27A3
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B3.name
                value = state.section27B3
                onChange = { event -> onChangeNumber(event) }
            }
            Label {
                label = "[Regions]"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A4.name
                value = state.section27A4
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B4.name
                value = state.section27B4
                onChange = { event -> onChangeNumber(event) }
            }

            SectionSubHeading {
                subHeading = "Permanent Employees"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A5.name
                value = state.section27A5
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B5.name
                value = state.section27B5
                onChange = { event -> onChangeNumber(event) }
            }
            Label {
                label = "Male"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A6.name
                value = state.section27A6
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B6.name
                value = state.section27B6
                onChange = { event -> onChangeNumber(event) }
            }
            Label {
                label = "Female"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A7.name
                value = state.section27A7
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B7.name
                value = state.section27B7
                onChange = { event -> onChangeNumber(event) }
            }
            Label {
                label = "[Regions]"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A8.name
                value = state.section27A8
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B8.name
                value = state.section27B8
                onChange = { event -> onChangeNumber(event) }
            }

            SectionSubHeading {
                subHeading = "Non-Guaranteed Hours Employees"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A9.name
                value = state.section27A9
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B9.name
                value = state.section27B9
                onChange = { event -> onChangeNumber(event) }
            }
            Label {
                label = "Male"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A10.name
                value = state.section27A10
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B10.name
                value = state.section27B10
                onChange = { event -> onChangeNumber(event) }
            }
            Label {
                label = "Female"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A11.name
                value = state.section27A11
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B11.name
                value = state.section27B11
                onChange = { event -> onChangeNumber(event) }
            }
            Label {
                label = "[Regions]"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A12.name
                value = state.section27A12
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B12.name
                value = state.section27B12
                onChange = { event -> onChangeNumber(event) }
            }

            SectionSubHeading {
                subHeading = "Full-Time Employees"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A13.name
                value = state.section27A13
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B13.name
                value = state.section27B13
                onChange = { event -> onChangeNumber(event) }
            }
            Label {
                label = "Male"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A14.name
                value = state.section27A14
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B14.name
                value = state.section27B14
                onChange = { event -> onChangeNumber(event) }
            }
            Label {
                label = "Female"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A15.name
                value = state.section27A15
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B15.name
                value = state.section27B15
                onChange = { event -> onChangeNumber(event) }
            }
            Label {
                label = "[Regions]"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A16.name
                value = state.section27A16
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B16.name
                value = state.section27B16
                onChange = { event -> onChangeNumber(event) }
            }

            SectionSubHeading {
                subHeading = "Part-Time Employees"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A17.name
                value = state.section27A17
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B17.name
                value = state.section27B17
                onChange = { event -> onChangeNumber(event) }
            }
            Label {
                label = "Male"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A18.name
                value = state.section27A18
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B18.name
                value = state.section27B18
                onChange = { event -> onChangeNumber(event) }
            }
            Label {
                label = "Female"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A19.name
                value = state.section27A19
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B19.name
                value = state.section27B19
                onChange = { event -> onChangeNumber(event) }
            }
            Label {
                label = "[Regions]"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A20.name
                value = state.section27A20
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B20.name
                value = state.section27B20
                onChange = { event -> onChangeNumber(event) }
            }

            SectionSubHeading {
                subHeading = "Temporary Employees"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A21.name
                value = state.section27A21
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B21.name
                value = state.section27B21
                onChange = { event -> onChangeNumber(event) }
            }
            Label {
                label = "Male"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A22.name
                value = state.section27A22
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B22.name
                value = state.section27B22
                onChange = { event -> onChangeNumber(event) }
            }
            Label {
                label = "Female"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A23.name
                value = state.section27A23
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B23.name
                value = state.section27B23
                onChange = { event -> onChangeNumber(event) }
            }
            Label {
                label = "[Regions]"
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_A24.name
                value = state.section27A24
                onChange = { event -> onChangeNumber(event) }
            }
            ESGNumberFieldWrapper {
                name = ActivitiesAndWorkersField.SECTION_2_7_B24.name
                value = state.section27B24
                onChange = { event -> onChangeNumber(event) }
            }
        }
    }

    Box {
        Label {
            label =
                "Describe methodologies and assumptions used to compile the data, and whether the numbers are from the end of the reporting period or an average of the reporting period, as well as and contextual information"
        }
        ESGTextAreaFieldWrapper {
            name = ActivitiesAndWorkersField.SECTION_2_7_FIELD1.name
            value = state.section27Field1
            onChange = { event -> onChangeTextArea(event) }
        }
    }

}