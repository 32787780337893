package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import emotion.react.css
import mui.icons.material.Add
import mui.icons.material.Delete
import mui.material.*
import react.FC
import react.PropsWithChildren
import react.ReactNode
import web.cssom.*

external interface DynamicTableWrapperrProps : PropsWithChildren {
    var addRows: () -> Unit?
    var deleteRows: () -> Unit?
    var items:  MutableList<dynamic>
    var action: DynamicTableAction
    var variant: DynamicTableVariant?
    var addRowBtnText: String?
}

val DynamicTableWrapper = FC<DynamicTableWrapperrProps> {props ->
    val dynamicTableVariant = props.variant ?: DynamicTableVariant.LIST
    val addRowBtnText = props.addRowBtnText ?: "row"

    Box {
        css {
            display = Display.flex
            flexDirection = FlexDirection.row
            gap = 5.px
        }
        Box {
            css {
            flexGrow = number(1.0)
        }
            +props.children
        }
        if(props.action  === DynamicTableAction.ADD) {
            Box {
                css {
                    display = Display.flex
                    alignItems = AlignItems.center
                }
                if(dynamicTableVariant == DynamicTableVariant.TABLE) {
                    Button {
                        variant = ButtonVariant.contained
                        css {
                            margin = 20.px
                        }
                        +"Add a new  $addRowBtnText"
                        onClick = {
                            props.addRows()
                        }
                    }
                }
                if(dynamicTableVariant == DynamicTableVariant.LIST) {
                    Tooltip {
                        title = ReactNode("Add a new $addRowBtnText")
                        IconButton {
                            onClick = {
                                props.addRows()
                            }
                            Add()
                        }
                    }
                }
            }
        }
        if(props.action === DynamicTableAction.DELETE && props.items.size > 1) {
            Box {
                css {
                    display = Display.flex
                    alignItems = AlignItems.flexStart
                }
                Tooltip {
                    title = ReactNode("Delete This entry")
                    IconButton {
                        onClick = {
                            props.deleteRows()
                        }
                        Delete()
                    }
                }
            }
        }
    }
}