package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.DialogSpinner
import com.ecosave.watch.portal.helpers.common.OperationType
import com.ecosave.watch.portal.helpers.common.handleNotificationStatus
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.EsgSubSection
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.helpers.esg.esgPatch
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.StrategyPoliciesPracticesState
import com.ecosave.watch.portal.services.esg.esgDownloadReportSection
import emotion.react.css
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControlVariant
import mui.material.TextField
import mui.system.Box
import mui.system.sx
import react.FC
import react.Props
import react.StateSetter
import react.dom.html.ReactHTML.label
import react.dom.onChange
import react.useState
import web.cssom.px
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement
import web.html.InputType
import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles as styles

external interface StrategyPoliciesPracticesComponentProps : Props {
    var esgReportStateProps: EsgReportState
    var esgReportStateSetterProps: StateSetter<EsgReportState>
}

val StrategyPoliciesPracticesComponent = FC<StrategyPoliciesPracticesComponentProps> { props ->
    var isLoading by useState(false)
    val (notificationState, notificationStateSetter) = useState(NotificationState())
    var esgReportState = props.esgReportStateProps

    esgReportState.report?.strategyPoliciesPractices?.let { strategyState ->

        var strategyPoliciesPracticesState = strategyState
        var esgReportStateSetter = props.esgReportStateSetterProps
        fun setStrategyPoliciesPracticesState(newStrategyPoliciesPracticesState: StrategyPoliciesPracticesState) {
            esgReportStateSetter(
                esgReportState.copy(
                    report = esgReportState.report?.copy(
                        strategyPoliciesPractices = newStrategyPoliciesPracticesState
                    )
                )
            )
        }
        Box {
            className = styles.MAIN_ESG_FORM.cssClass
            Box {
                Button {
                    className = styles.ESG_PREVIEW.cssClass
                    variant = ButtonVariant.contained
                    onClick = {
                        isLoading = true
                        mainScope.launch {
                            val status = esgDownloadReportSection(
                                props.esgReportStateProps.reportFileName,
                                EsgSection.GENERAL_DISCLOSURES

                            )
                            isLoading = false
                            handleNotificationStatus(
                                status,
                                notificationState,
                                notificationStateSetter,
                                OperationType.DOWNLOAD
                            )
                        }
                    }
                    +"Preview"
                }
            }
            Box {
                label {
                    className = styles.ESG_FIRST_FONT.cssClass
                    +"Strategy,Policies And Practices"
                }
                Box {
                    label {
                        className = styles.ESG_SECOND_FONT.cssClass
                        +"Statement On Sustainable Development Strategy"
                    }
                }
                Box {
                    label {
                        className = styles.ESG_FOURTH_FONT.cssClass
                        +"Statement from the highest governance body or most senior executive of the organization about the relevance of sustainable development to the organization and its strategy for contributing to sustainable development:"
                    }
                    TextField {
                        className = styles.ESG_INPUT_ITEM.cssClass

                        type = InputType.text
                        variant = FormControlVariant.outlined
                        value = strategyPoliciesPracticesState.section222A1
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setStrategyPoliciesPracticesState(
                                strategyPoliciesPracticesState.copy(
                                    section222A1 = target.value
                                )
                            )
                            esgPatch(
                                "section222A1",
                                JsonPrimitive(target.value),
                                EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                esgReportState.reportFileName
                            )
                        }
                    }
                    Box {
                        label {
                            className = styles.ESG_FIRST_FONT.cssClass
                            +"GRI 2-23 Policy commitments"
                        }
                    }
                    Box {
                        className = styles.ESG_FIVE_COLUMNS.cssClass
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                                +"Describe the policy commitment that's responsible for business conduct"
                            }
                        }
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                                +"Link if publicly available, or explanation for why it’s not available"
                            }
                        }
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                                +"Level at which policy was approved within the organization"
                            }
                        }
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                                +"The extent to which policy applies to organizational activities"
                            }
                        }

                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                                +"How the policy is communicated to workers, business partners, and other relevant parties"
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass
                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section223A1
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section223A1 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section223A1",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section223B1
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section223B1 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section223B1",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section223C1
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section223C1 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section223C1",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section223D1
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section223D1 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section223D1",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section223E1
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section223E1 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section223E1",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section223A2
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section223A2 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section223A2",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section223B2
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section223B2 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section223B2",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section223C2
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section223C2 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section223C2",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section223D2
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section223D2 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section223D2",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section223E2
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section223E2 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section223E2",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section223A3
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section223A3 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section223A3",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section223B3
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section223B3 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section223B3",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section223C3
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section223C3 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section223C3",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section223D3
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section223D3 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section223D3",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section223E3
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section223E3 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section223E3",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                    }
                    Box {
                        label {
                            className = styles.ESG_FOURTH_FONT.cssClass
                            +"Describe specific policy commitment to respect human rights, including:"
                        }
                        label {
                            className = styles.ESG_FOURTH_FONT.cssClass
                            css {
                                marginLeft = 20.px
                            }
                            +"The internationally recognized human rights that the commitment covers The categories of stakeholders, including at-risk or vulnerable groups, that the organization gives particular attention to in the commitment:"
                        }
                        TextField {
                            className = styles.ESG_INPUT_ITEM.cssClass

                            type = InputType.text
                            variant = FormControlVariant.outlined
                            value = strategyPoliciesPracticesState.section223Field1
                            multiline = true
                            minRows = ReportConstants.TEXT_AREA_MIN_ROWS
                            maxRows = ReportConstants.TEXT_AREA_MAX_ROWS

                            onChange = {
                                val target = it.target as HTMLTextAreaElement
                                setStrategyPoliciesPracticesState(
                                    strategyPoliciesPracticesState.copy(
                                        section223Field1 = target.value
                                    )
                                )
                                esgPatch(
                                    "section223Field1",
                                    JsonPrimitive(target.value),
                                    EsgSubSection.ACTIVITIES_AND_WORKERS.name,
                                    esgReportState.reportFileName
                                )
                            }
                        }
                    }
                    Box {
                        label {
                            className = styles.ESG_FIRST_FONT.cssClass
                            +"GRI 2-24 Embedding policy commitments"
                        }
                    }
                    Box {
                        className = styles.ESG_TWO_COLUMNS.cssClass
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                                +"Policy commitment"
                            }
                        }
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                                +"How organization embeds policy through activities and business relationships"
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section224A1
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section224A1 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section224A1",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section224B1
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section224B1 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section224B1",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section224A2
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section224A2 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section224A2",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section224B2
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section224B2 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section224B2",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                    }
                    Box {
                        label {
                            className = styles.ESG_FIRST_FONT.cssClass
                            +"GRI 2-25 Process to remediate negative impacts"
                        }
                    }
                    Box {
                        label {
                            className = styles.ESG_FOURTH_FONT.cssClass
                            +"Commitments to provide for or cooperate in remediation of negative impacts that the organization identifies it has caused or contributed to"
                        }
                        TextField {
                            className = styles.ESG_INPUT_ITEM.cssClass

                            type = InputType.text
                            variant = FormControlVariant.outlined
                            value = strategyPoliciesPracticesState.section225A1
                            onChange = {
                                val target = it.target as HTMLInputElement
                                setStrategyPoliciesPracticesState(
                                    strategyPoliciesPracticesState.copy(
                                        section225A1 = target.value
                                    )
                                )
                                esgPatch(
                                    "section225A1",
                                    JsonPrimitive(target.value),
                                    EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                    esgReportState.reportFileName
                                )
                            }
                        }
                    }
                    Box {
                        label {
                            className = styles.ESG_FOURTH_FONT.cssClass
                            +"Approach to identify and address grievances (include grievance mechanisms that the organization has established)"
                        }
                        TextField {
                            className = styles.ESG_INPUT_ITEM.cssClass

                            type = InputType.text
                            variant = FormControlVariant.outlined
                            value = strategyPoliciesPracticesState.section225A2
                            onChange = {
                                val target = it.target as HTMLInputElement
                                setStrategyPoliciesPracticesState(
                                    strategyPoliciesPracticesState.copy(
                                        section225A2 = target.value
                                    )
                                )
                                esgPatch(
                                    "section225A2",
                                    JsonPrimitive(target.value),
                                    EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                    esgReportState.reportFileName
                                )
                            }
                        }
                    }
                    Box {
                        label {
                            className = styles.ESG_FOURTH_FONT.cssClass
                            +"Other processes by which the organization provides for or cooperates in the remediation of negative impacts that it identifies it has caused or contributed to"
                        }
                        TextField {
                            className = styles.ESG_INPUT_ITEM.cssClass

                            type = InputType.text
                            variant = FormControlVariant.outlined
                            value = strategyPoliciesPracticesState.section225A3
                            onChange = {
                                val target = it.target as HTMLInputElement
                                setStrategyPoliciesPracticesState(
                                    strategyPoliciesPracticesState.copy(
                                        section225A3 = target.value
                                    )
                                )
                                esgPatch(
                                    "section225A3",
                                    JsonPrimitive(target.value),
                                    EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                    esgReportState.reportFileName
                                )
                            }
                        }
                    }
                    Box {
                        label {
                            className = styles.ESG_FOURTH_FONT.cssClass
                            +"How stakeholders who are intended users of the grievance mechanisms are involved in the design, review, operation, and improvement of these mechanisms"
                        }
                        TextField {
                            className = styles.ESG_INPUT_ITEM.cssClass

                            type = InputType.text
                            variant = FormControlVariant.outlined
                            value = strategyPoliciesPracticesState.section225A4
                            onChange = {
                                val target = it.target as HTMLInputElement
                                setStrategyPoliciesPracticesState(
                                    strategyPoliciesPracticesState.copy(
                                        section225A4 = target.value
                                    )
                                )
                                esgPatch(
                                    "section225A4",
                                    JsonPrimitive(target.value),
                                    EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                    esgReportState.reportFileName
                                )
                            }
                        }
                    }
                    Box {
                        label {
                            className = styles.ESG_FOURTH_FONT.cssClass
                            +"How the organization tracks the effectiveness of the grievance mechanisms and other remediation process, and report example of their effectiveness, including stakeholder feedback"
                        }
                        TextField {
                            className = styles.ESG_INPUT_ITEM.cssClass

                            type = InputType.text
                            variant = FormControlVariant.outlined
                            value = strategyPoliciesPracticesState.section225A5
                            onChange = {
                                val target = it.target as HTMLInputElement
                                setStrategyPoliciesPracticesState(
                                    strategyPoliciesPracticesState.copy(
                                        section225A5 = target.value
                                    )
                                )
                                esgPatch(
                                    "section225A5",
                                    JsonPrimitive(target.value),
                                    EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                    esgReportState.reportFileName
                                )
                            }
                        }
                    }
                    Box {
                        label {
                            className = styles.ESG_FIRST_FONT.cssClass
                            +"GRI 2-26 Mechanisms for seeking advice and raising concerns"
                        }
                    }
                    Box {
                        className = styles.ESG_TWO_COLUMNS.cssClass
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                                +""
                            }
                        }
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                                +"Mechanisms available"
                            }
                        }

                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT.cssClass
                                +"Seeking advice on implementing the organization’s policies and practices for responsible business conduct"
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section226A1
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section226A1 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section226A1",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }

                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT.cssClass
                                +"Raising concerns about the organization’s business conduct"
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section226A2
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section226A2 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section226A2",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                    }
                    Box {
                        label {
                            className = styles.ESG_FIRST_FONT.cssClass
                            +"GRI 2-27 Compliance with laws and regulations"
                        }
                    }
                    Box {
                        className = styles.ESG_TWO_COLUMNS.cssClass
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT.cssClass
                                +""
                            }
                        }
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                                +"Number of instances"
                            }
                        }
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT.cssClass
                                +"Significant instances of non-compliance with laws and regulations"
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass
                                type = InputType.number
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section227I1
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section227I1 = target.value.toIntOrNull() ?: 0
                                        )
                                    )
                                    esgPatch(
                                        "section227I1",
                                        JsonPrimitive(target.value.toIntOrNull() ?: 0),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT.cssClass
                                +"Instances for which fines were occured"
                            }
                        }
                        Box {
                            Box {
                                TextField {
                                    className = styles.ESG_INPUT_ITEM.cssClass

                                    type = InputType.number
                                    variant = FormControlVariant.outlined
                                    value = strategyPoliciesPracticesState.section227I2
                                    onChange = {
                                        val target = it.target as HTMLInputElement
                                        setStrategyPoliciesPracticesState(
                                            strategyPoliciesPracticesState.copy(
                                                section227I2 = target.value.toIntOrNull() ?: 0
                                            )
                                        )
                                        esgPatch(
                                            "section227I2",
                                            JsonPrimitive(target.value.toIntOrNull() ?: 0),
                                            EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                            esgReportState.reportFileName
                                        )
                                    }
                                }
                            }
                        }
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT.cssClass
                                +"Instances for which non-monetary sanctions were occured"
                            }
                        }
                        Box {
                            Box {
                                TextField {
                                    className = styles.ESG_INPUT_ITEM.cssClass

                                    type = InputType.number
                                    variant = FormControlVariant.outlined
                                    value = strategyPoliciesPracticesState.section227I3
                                    onChange = {
                                        val target = it.target as HTMLInputElement
                                        setStrategyPoliciesPracticesState(
                                            strategyPoliciesPracticesState.copy(
                                                section227I3 = target.value.toIntOrNull() ?: 0
                                            )
                                        )
                                        esgPatch(
                                            "section227I3",
                                            JsonPrimitive(target.value.toIntOrNull() ?: 0),
                                            EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                            esgReportState.reportFileName
                                        )
                                    }
                                }
                            }
                        }
                    }
                    Box {
                        sx {
                            paddingTop = 10.px
                            paddingBottom = 10.px
                        }
                    }
                    Box {
                        className = styles.ESG_THREE_COLUMNS.cssClass
                        Box {
                            className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                            + ""
                        }
                        Box {
                            className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                            +"Number of Fines"
                        }
                        Box {
                            className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                            +"Monetary Value"
                        }
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT.cssClass
                                +"Instances of fines for non-compliance with laws and regulations that were paid during the reporting period"
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.number
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section227F1
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section227F1 = target.value.toIntOrNull() ?: 0
                                        )
                                    )
                                    esgPatch(
                                        "section227F1",
                                        JsonPrimitive(target.value.toIntOrNull() ?: 0),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.number
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section227M1
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section227M1 = target.value.toDoubleOrNull() ?: 0.0
                                        )
                                    )
                                    esgPatch(
                                        "section227M1",
                                        JsonPrimitive(target.value.toDoubleOrNull() ?: 0.0),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT.cssClass
                                +"•Fines from previous reporting periods"
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.number
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section227F3
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section227F3 = target.value.toIntOrNull() ?: 0
                                        )
                                    )
                                    esgPatch(
                                        "section227F3",
                                        JsonPrimitive(target.value.toIntOrNull() ?: 0),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.number
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section227M3
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section227M3 = target.value.toDoubleOrNull() ?: 0.0
                                        )
                                    )
                                    esgPatch(
                                        "section227M3",
                                        JsonPrimitive(target.value.toDoubleOrNull() ?: 0.0),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT.cssClass
                                +"•Fines from the reporting period"
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.number
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section227F2
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section227F2 = target.value.toIntOrNull() ?: 0
                                        )
                                    )
                                    esgPatch(
                                        "section227F2",
                                        JsonPrimitive(target.value.toIntOrNull() ?: 0),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.number
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section227M2
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section227M2 = target.value.toDoubleOrNull() ?: 0.0
                                        )
                                    )
                                    esgPatch(
                                        "section227M2",
                                        JsonPrimitive(target.value.toDoubleOrNull() ?: 0.0),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                    }
                    Box {
                        label {
                            className = styles.ESG_FOURTH_FONT.cssClass
                            +"Describe how it has determined significant instances of non-compliance."
                        }
                        TextField {
                            className = styles.ESG_INPUT_ITEM.cssClass

                            type = InputType.text
                            variant = FormControlVariant.outlined
                            value = strategyPoliciesPracticesState.section227Field1
                            multiline = true
                            minRows = ReportConstants.TEXT_AREA_MIN_ROWS
                            maxRows = ReportConstants.TEXT_AREA_MAX_ROWS

                            onChange = {
                                val target = it.target as HTMLTextAreaElement
                                setStrategyPoliciesPracticesState(
                                    strategyPoliciesPracticesState.copy(
                                        section227Field1 = target.value
                                    )
                                )
                                esgPatch(
                                    "section227Field1",
                                    JsonPrimitive(target.value),
                                    EsgSubSection.ACTIVITIES_AND_WORKERS.name,
                                    esgReportState.reportFileName
                                )
                            }
                        }
                    }
                    Box {
                        label {
                            className = styles.ESG_FIRST_FONT.cssClass
                            +"GRI 2-28 Membership associations"
                        }
                    }
                    Box {
                        className = styles.ESG_TWO_COLUMNS.cssClass
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                                +"Type of Association"
                            }
                        }
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                                +"Associations the organization is a member of"
                            }
                        }
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT.cssClass
                                +"Industry associations"
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section228A1
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section228A1 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section228A1",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT.cssClass
                                +"Other membership associations"
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section228A2
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section228A2 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section228A2",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT.cssClass
                                +"National advocacy organizations"
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section228A3
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section228A3 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section228A3",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                        Box {
                            label {
                                className = styles.ESG_FOURTH_FONT.cssClass
                                +"International advocacy organizations"
                            }
                        }
                        Box {
                            TextField {
                                className = styles.ESG_INPUT_ITEM.cssClass

                                type = InputType.text
                                variant = FormControlVariant.outlined
                                value = strategyPoliciesPracticesState.section228A4
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setStrategyPoliciesPracticesState(
                                        strategyPoliciesPracticesState.copy(
                                            section228A4 = target.value
                                        )
                                    )
                                    esgPatch(
                                        "section228A4",
                                        JsonPrimitive(target.value),
                                        EsgSubSection.STRATEGY_POLICIES_PRACTICES.name,
                                        esgReportState.reportFileName
                                    )
                                }
                            }
                        }
                    }
                }
            }
            DialogSpinner {
                open = isLoading
                message = "Downloading..."
            }
            AlertNotifications {
                open = notificationState.visible
                status = notificationState.status
                message = notificationState.message
                closeNotification = {
                    notificationStateSetter(
                        notificationState.copy(
                            visible = false
                        )
                    )
                }
            }

        }
    }
}
