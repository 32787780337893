package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.helpers.esg.TOCSectionsEnum
import com.ecosave.watch.portal.helpers.esg.getAllPercentages
import com.ecosave.watch.portal.helpers.esg.getPercentageComplete
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.UpdateEnRichedToc
import com.ecosave.watch.portal.services.esg.updateEnrichedToc
import kotlinx.coroutines.launch
import mui.icons.material.ExpandMore
import mui.icons.material.InfoOutlined
import mui.material.Accordion
import mui.material.AccordionDetails
import mui.material.AccordionSummary
import mui.material.Box
import mui.material.Checkbox
import mui.material.CircularProgressColor
import mui.material.FormControlLabel
import mui.material.FormGroup
import mui.material.Size
import mui.material.Tooltip
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.create
import react.useState
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.Float
import web.cssom.FontWeight
import web.cssom.Position
import web.cssom.pct
import web.cssom.px
import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles as styles

external interface TableOfContentsComponentProps : Props {
    var esgReportStateProps: EsgReportState
    var esgReportStateSetterProps: StateSetter<EsgReportState>
    var tocClickedSection: (TOCSectionsEnum) -> Unit
}

val TableOfContentsComponent = FC<TableOfContentsComponentProps> { props ->

    val mainTableOfContent by useState(props.esgReportStateProps.mainTableOfContents)

    Box {
        sx {
            marginLeft = 8.pct
            display = Display.flex
            flexDirection = FlexDirection.column
            alignItems = AlignItems.center
            float = Float.right
            top = 50.pct
            right = 1.pct
            position = Position.fixed
        }
        Typography {
            sx {
                fontWeight = FontWeight.bold
            }
            variant = TypographyVariant.h6
            +"Total Progress:"
        }
        Box {
            sx {
                position = Position.relative
                display = Display.inlineFlex
            }
            mainTableOfContent?.let {
                EsgPercentComponent {
                    currentPercent = it.totalPercentCompleted
                    color = CircularProgressColor.secondary
                }
            }

        }
    }
    Box {
        className = styles.MAIN_ESG_FORM.cssClass
        mainTableOfContent?.enrichedTableOfContents?.forEach { enrichedTableOfContent ->
            Accordion {
                AccordionSummary {
                    expandIcon = ExpandMore.create {}
                    Typography {
                        sx {
                            marginTop = 10.px
                        }
                        variant = TypographyVariant.h6
                        +enrichedTableOfContent.mainTitleStatusTracker.esgSection.description
                    }
                    Tooltip {
                        title = ReactNode("Percent Completed")
                        Box {
                            sx {
                                marginLeft = 20.px
                                position = Position.relative
                                display = Display.inlineFlex
                            }
                            EsgPercentComponent {
                                currentPercent = enrichedTableOfContent.mainTitleStatusTracker.percentCompleted
                                color = CircularProgressColor.primary
                            }
                        }
                    }
                }
                enrichedTableOfContent.enrichedSubContents.forEach { enrichedSubContent ->
                    if (enrichedSubContent.subTitleStatusTracker?.subSection != null) {
                        enrichedTableOfContent.mainTitleStatusTracker.percentCompleted = getAllPercentages(enrichedTableOfContent.enrichedSubContents)
                        AccordionSummary {
                            Typography {
                                sx {
                                    marginTop = 10.px
                                }
                                className = styles.TOC_ICON_SECOND.cssClass
                                variant = TypographyVariant.h6
                                onClick = {
                                    props.tocClickedSection(TOCSectionsEnum.valueOf(enrichedSubContent.subTitleStatusTracker.subSection))
                                }
                                +enrichedSubContent.subTitleStatusTracker.subTitle
                            }
                            Tooltip {
                                title = ReactNode("Percent Completed")
                                Box {
                                    sx {
                                        marginLeft = 20.px
                                        marginBottom = 0.pct
                                        position = Position.relative
                                        display = Display.inlineFlex
                                    }
                                    EsgPercentComponent {
                                        currentPercent = enrichedSubContent.subTitleStatusTracker.percentCompleted
                                        color = CircularProgressColor.primary
                                    }
                                }
                            }
                        }
                        enrichedTableOfContent.mainTitleStatusTracker.modifiedBy?.let {
                            Box {
                                sx {
                                    paddingLeft = 50.px
                                    paddingTop = 0.pct
                                }
                                Typography {
                                    sx {
                                        fontWeight = FontWeight.bold
                                    }
                                    variant = TypographyVariant.body2
                                    +"Last Modified By: ${it.firstName} ${it.lastName}"
                                }
                                Tooltip {
                                    sx {
                                        marginLeft = 5.px
                                    }
                                    InfoOutlined()
                                    title = ReactNode(it.emailAddress)
                                }
                            }
                        }

                        AccordionDetails {
                            sx {
                                paddingLeft = 50.px
                                paddingTop = 0.pct
                            }
                            FormGroup {
                                Box {
                                    sx {
                                        padding = 0.pct
                                        display = Display.flex
                                        flexDirection = FlexDirection.row
                                    }
                                    Typography {
                                        variant = TypographyVariant.caption
                                        +"Status"
                                    }
                                    Typography {
                                        sx {
                                            marginLeft = 10.px
                                        }
                                        variant = TypographyVariant.caption
                                        +"Section"
                                    }
                                }
                                enrichedSubContent.itemStatusTrackerList.forEach { item ->
                                    val (activeSection, setActiveSection) = useState(item.completed)
                                    FormControlLabel {
                                        control = Tooltip.create {
                                            Checkbox {
                                                checked = activeSection
                                                size = Size.small
                                                onChange = { event, _ ->
                                                    item.completed = event.target.checked
                                                    if (event.target.checked) {
                                                        if (enrichedSubContent.itemStatusTrackerList.all { item -> item.completed }) {
                                                            enrichedSubContent.subTitleStatusTracker.percentCompleted = 100
                                                        } else {
                                                            enrichedSubContent.subTitleStatusTracker.percentCompleted += (100 / enrichedSubContent.itemStatusTrackerList.size)
                                                        }
                                                    } else {
                                                        enrichedSubContent.subTitleStatusTracker.percentCompleted -= (100 / enrichedSubContent.itemStatusTrackerList.size)
                                                    }
                                                    enrichedTableOfContent.mainTitleStatusTracker.percentCompleted =
                                                        getAllPercentages(enrichedTableOfContent.enrichedSubContents)
                                                    setActiveSection(!activeSection)
                                                    mainTableOfContent?.let {
                                                        mainScope.launch {
                                                            val percentageCompleted = getPercentageComplete(props.esgReportStateProps)
                                                            updateEnrichedToc(
                                                                UpdateEnRichedToc(props.esgReportStateProps.reportFileName, it),
                                                                percentageCompleted
                                                            )
                                                        }
                                                    }
                                                }
                                            }
                                            title = ReactNode(
                                                when (activeSection) {
                                                    true -> "Mark as incomplete."
                                                    false -> "Mark as complete."
                                                }
                                            )
                                        }
                                        label = Typography.create {
                                            variant = TypographyVariant.body1
                                            +item.item
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        AccordionDetails {
                            sx {
                                paddingLeft = 50.px
                                paddingTop = 0.pct
                            }
                            FormGroup {
                                enrichedTableOfContent.mainTitleStatusTracker.modifiedBy?.let {
                                    Box {
                                        sx {
                                            display = Display.flex
                                        }
                                        Typography {
                                            sx {
                                                fontWeight = FontWeight.bold
                                            }
                                            variant = TypographyVariant.body2
                                            +"Last Modified By: ${it.firstName} ${it.lastName}"
                                        }
                                        Tooltip {
                                            sx {
                                                marginLeft = 5.px
                                            }
                                            InfoOutlined()
                                            title = ReactNode(it.emailAddress)
                                        }
                                    }
                                }
                                Box {
                                    sx {
                                        padding = 0.pct
                                        display = Display.flex
                                        flexDirection = FlexDirection.row
                                    }
                                    Typography {
                                        variant = TypographyVariant.caption
                                        +"Status"
                                    }
                                    Typography {
                                        sx {
                                            marginLeft = 10.px
                                        }
                                        variant = TypographyVariant.caption
                                        +"Section"
                                    }
                                }
                                enrichedSubContent.itemStatusTrackerList.forEach { item ->
                                    val (activeSection, setActiveSection) = useState(item.completed)
                                    FormControlLabel {
                                        control = Tooltip.create {
                                            Checkbox {
                                                checked = activeSection
                                                size = Size.small
                                                onChange = { event, _ ->
                                                    item.completed = event.target.checked
                                                    if (event.target.checked) {
                                                        if (enrichedSubContent.itemStatusTrackerList.all { item -> item.completed }) {
                                                            enrichedTableOfContent.mainTitleStatusTracker.percentCompleted = 100
                                                        } else {
                                                            enrichedTableOfContent.mainTitleStatusTracker.percentCompleted += (100 / enrichedSubContent.itemStatusTrackerList.size)
                                                        }
                                                    } else {
                                                        enrichedTableOfContent.mainTitleStatusTracker.percentCompleted -= (100 / enrichedSubContent.itemStatusTrackerList.size)
                                                    }
                                                    setActiveSection(!activeSection)
                                                    mainTableOfContent?.let {
                                                        mainScope.launch {
                                                            val percentageCompleted = getPercentageComplete(props.esgReportStateProps)
                                                            updateEnrichedToc(
                                                                UpdateEnRichedToc(props.esgReportStateProps.reportFileName, it),
                                                                percentageCompleted
                                                            )
                                                        }
                                                    }
                                                }
                                            }
                                            title = ReactNode(
                                                when (activeSection) {
                                                    true -> "Mark as incomplete."
                                                    false -> "Mark as complete."
                                                }
                                            )
                                        }
                                        label = Typography.create {
                                            className = styles.TOC_ICON_SECOND.cssClass
                                            variant = TypographyVariant.body1
                                            +item.item
                                            onClick = {
                                                props.tocClickedSection(TOCSectionsEnum.valueOf(enrichedTableOfContent.mainTitleStatusTracker.esgSection.name))
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}