package com.ecosave.watch.portal.components.esg.customerhealthnsafety

import com.ecosave.watch.portal.components.esg.formcontrols.*
import com.ecosave.watch.portal.helpers.esg.CustomerHealthNSafetyFeildsEnum as FieldEnum
import com.ecosave.watch.portal.models.esg.CustomerHealthAndSafetyState
import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles
import com.ecosave.watch.portal.styles.esg.EconomicPerformanceStyles
import com.ecosave.watch.portal.styles.esg.IndirectEconomicImpacts
import com.ecosave.watch.portal.styles.esg.TaxStyles
import mui.material.Box
import mui.system.sx
import react.ChildrenBuilder
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.onChange
import web.cssom.px
import web.html.HTMLDivElement

external interface Section416Props: Props {
    var onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit
    var onChangeNumber: (FormEvent<HTMLDivElement>) -> Unit
    var state: CustomerHealthAndSafetyState
}

val Section416 = FC<Section416Props>("Section416") { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onChangeNumber = props.onChangeNumber
    val state = props.state

    SectionMainTitle {
        title = "Customer Health and Safety 2016"
    }
    SectionSubHeading {
        subHeading = "416-1 ASSESSMENT OF THE HEALTH AND SAFETY IMPACTS OF PRODUCT AND SERVICE CATEGORIES"
    }

    Box {
        className = ESGReportInputStyles.TABLE_WRAPPER.cssClass
        Box{
            className = TaxStyles.SECTION_207_TABLE.cssClass
            Label {
                label = "Percentage of [significant product and service category] for which health and safety impacts are assessed for improvement?"
            }
            ESGTextAreaFieldWrapper {
                name = FieldEnum.SECTION_416_1_A1.name
                value = state.section4161A1
                onChange = { event -> onChangeTextArea(event) }
            }
        }
    }

    SectionSubHeading {
        subHeading = "416-2 INCIDENTS OF NON-COMPLIANCE CONCERNING THE HEALTH AND SAFETY IMPACTS OF PRODUCTS AND SERVICES"
    }

    Box {
        className = ESGReportInputStyles.TABLE_WRAPPER.cssClass

        Box{
            className = TaxStyles.SECTION_207_TABLE.cssClass
            Label {
                label = ""
            }
            Box {
                className = IndirectEconomicImpacts.SECTION_203_2_TABLE.cssClass
                Label {
                    label = "Current Year"
                }
                Label {
                    label = "Previous Year"
                }
            }
        }

        build416Row(
            mainLabel = "Total number of incidents of non-compliance with regulations and/or voluntary codes concerning the health and safety impacts of products and services",
            currentName = FieldEnum.SECTION_416_2_CURRENT1,
            currentValue = state.section4162Current1,
            previousName = FieldEnum.SECTION_416_2_PREVIOUS1,
            previousValue = state.section4162Previous1,
            onChangeNumber = { event -> onChangeNumber(event) }
        )
        build416Row(
            mainLabel = "Number of incidents of non-compliance with regulations resulting in a fine or penalty",
            currentName = FieldEnum.SECTION_416_2_CURRENT2,
            currentValue = state.section4162Current2,
            previousName = FieldEnum.SECTION_416_2_PREVIOUS2,
            previousValue = state.section4162Previous2,
            onChangeNumber = { event -> onChangeNumber(event) }
        )
        build416Row(
            mainLabel = "Number of incidents of non-compliance with regulations resulting in a warning",
            currentName = FieldEnum.SECTION_416_2_CURRENT3,
            currentValue = state.section4162Current3,
            previousName = FieldEnum.SECTION_416_2_PREVIOUS3,
            previousValue = state.section4162Previous3,
            onChangeNumber = { event -> onChangeNumber(event) }
        )
        build416Row(
            mainLabel = "Number of incidents of non-compliance with voluntary codes",
            currentName = FieldEnum.SECTION_416_2_CURRENT4,
            currentValue = state.section4162Current4,
            previousName = FieldEnum.SECTION_416_2_PREVIOUS4,
            previousValue = state.section4162Previous4,
            onChangeNumber = { event -> onChangeNumber(event) }
        )
    }
    Box {
        className = ESGReportInputStyles.TABLE_WRAPPER.cssClass
        Box{
            className = EconomicPerformanceStyles.SECTION_201_4_TABLE_INNER.cssClass
            sx {
                margin = 10.px
            }
            Label {
                label = "If the organization has not identified any non-compliance with regulations and/or voluntary codes, a brief statement of this fact is sufficient."
            }
            ESGTextAreaFieldWrapper {
                name = FieldEnum.SECTION_416_2_FIELD1.name
                value = state.section4162Field1
                onChange = { event -> onChangeTextArea(event) }
            }
        }
    }

}

fun ChildrenBuilder.build416Row(
    mainLabel: String,
    currentName: FieldEnum,
    currentValue: Any?,
    previousName: FieldEnum,
    previousValue: Any?,
    onChangeNumber: (FormEvent<HTMLDivElement>) -> Unit
): Unit = Box {
    className = TaxStyles.SECTION_207_TABLE.cssClass
    sx {
        margin = 10.px
    }
    Label {
        label = mainLabel
    }
    Box {
        className = IndirectEconomicImpacts.SECTION_203_2_TABLE.cssClass
        ESGNumberFieldWrapper {
            name = currentName.name
            value = currentValue
            onChange = { event -> onChangeNumber(event) }
        }
        ESGNumberFieldWrapper {
            name = previousName.name
            value = previousValue
            onChange = { event -> onChangeNumber(event) }
        }
    }
}