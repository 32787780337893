package com.ecosave.watch.portal.components.esg.economicperformance

import com.ecosave.watch.portal.helpers.common.YesNoEnum
import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.esg.*
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.*
import com.ecosave.watch.portal.services.esg.addEsgCollectionRow
import com.ecosave.watch.portal.services.esg.deleteEsgCollectionRow
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.Props
import react.StateSetter
import react.dom.events.ChangeEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement
import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles as styles


external interface EconomicPerformanceComponentProps : Props {
    var esgReportStateProps: EsgReportState
    var esgReportStateSetterProps: StateSetter<EsgReportState>
    var setInputState: StateSetter<CurrentInputState>
}


val EconomicPerformanceComponent = FC<EconomicPerformanceComponentProps> { props ->

    val esgState = props.esgReportStateProps
    val esgStateSetter = props.esgReportStateSetterProps

    val addRowFunction: (String) -> Unit = {fieldName ->
        mainScope.launch {
            addEsgCollectionRow(
                EsgCollectionAddRow(
                    objectName = EsgSection.ECONOMIC_PERFORMANCE.name,
                    reportFileName = esgState.reportFileName,
                    patchCollection = RowPatch(
                        pathFirst = fieldName
                    )
                )
            )
        }
    }

    val deleteRowFunction: (String, Int) -> Unit = {path,index ->
        mainScope.launch {
            deleteEsgCollectionRow(
                EsgCollectionDeleteRow(
                    objectPatchEnum= EsgSection.ECONOMIC_PERFORMANCE,
                    reportFileName=esgState.reportFileName,
                        pathFirst= path,
                        indexAt = index
                )
            )
        }
    }

    esgState.report?.economicPerformance?.let { state ->
        val stateSetter: (state: EconomicPerformanceState) -> Unit = {
            esgStateSetter(
                esgState.copy(
                    report = esgState.report.copy(
                        economicPerformance = state
                    )
                )
            )
        }

        val onChangeRadio: (ChangeEvent<HTMLInputElement>) -> Unit = { event ->
            val propertyName = EconomicPerformanceField.valueOf(event.target.name)
            val propertyValue = event.target.value

            if (propertyName == EconomicPerformanceField.SECTION_2014PART2_FIELD1 && propertyValue == YesNoEnum.NO.description) {
                state.section2014Part2Field1 = YesNoEnum.NO.description
            } else {
                state.section2014Part2Field1 =  if (propertyName == EconomicPerformanceField.SECTION_2014PART2_FIELD1) propertyValue else state.section2014Part2Field1
            }

                stateSetter(state)
                props.setInputState(
                    CurrentInputState(
                        parentPath = null,
                        childPath = propertyName.fieldName,
                        index = null,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSection.ECONOMIC_PERFORMANCE.name,
                        reportName = esgState.reportFileName
                    )
                )
        }

        val onChangeFunction: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->

            when (event.target) {
                is HTMLInputElement -> {
                    val target = event.target as HTMLInputElement

                    if (isValidDecimalNumber(target)) {
                        val propertyName = EconomicPerformanceField.valueOf(target.name)
                        val propertyValue = target.value.toDoubleOrNull()

                            state.section2011A1 = if (propertyName == EconomicPerformanceField.SECTION_2011A1) propertyValue else state.section2011A1
                            state.section2011B1 = if (propertyName == EconomicPerformanceField.SECTION_2011B1) propertyValue else state.section2011B1
                            state.section2011A2 = if (propertyName == EconomicPerformanceField.SECTION_2011A2) propertyValue else state.section2011A2
                            state.section2011B2 = if (propertyName == EconomicPerformanceField.SECTION_2011B2) propertyValue else state.section2011B2
                            state.section2011A3 = if (propertyName == EconomicPerformanceField.SECTION_2011A3) propertyValue else state.section2011A3
                            state.section2011B3 = if (propertyName == EconomicPerformanceField.SECTION_2011B3) propertyValue else state.section2011B3
                            state.section2011A4 = if (propertyName == EconomicPerformanceField.SECTION_2011A4) propertyValue else state.section2011A4
                            state.section2011B4 = if (propertyName == EconomicPerformanceField.SECTION_2011B4) propertyValue else state.section2011B4
                            state.section2011A5 = if (propertyName == EconomicPerformanceField.SECTION_2011A5) propertyValue else state.section2011A5
                            state.section2011B5 = if (propertyName == EconomicPerformanceField.SECTION_2011B5) propertyValue else state.section2011B5
                            state.section2011A6 = if (propertyName == EconomicPerformanceField.SECTION_2011A6) propertyValue else state.section2011A6
                            state.section2011B6 = if (propertyName == EconomicPerformanceField.SECTION_2011B6) propertyValue else state.section2011B6
                            state.section2011A7 = if (propertyName == EconomicPerformanceField.SECTION_2011A7) propertyValue else state.section2011A7
                            state.section2011B7 = if (propertyName == EconomicPerformanceField.SECTION_2011B7) propertyValue else state.section2011B7
                            state.section2011A8 = if (propertyName == EconomicPerformanceField.SECTION_2011A8) propertyValue else state.section2011A8
                            state.section2011B8 = if (propertyName == EconomicPerformanceField.SECTION_2011B8) propertyValue else state.section2011B8
                            state.section2011A9 = if (propertyName == EconomicPerformanceField.SECTION_2011A9) propertyValue else state.section2011A9
                            state.section2011B9 = if (propertyName == EconomicPerformanceField.SECTION_2011B9) propertyValue else state.section2011B9
                            state.section2013A1 = if (propertyName == EconomicPerformanceField.SECTION_2013A1) propertyValue else state.section2013A1
                            state.section2013A3 = if (propertyName == EconomicPerformanceField.SECTION_2013A3) propertyValue else state.section2013A3
                            state.section2013A4 = if (propertyName == EconomicPerformanceField.SECTION_2013A4) propertyValue else state.section2013A4
                            state.section2014B1 = if (propertyName == EconomicPerformanceField.SECTION_2014B1) propertyValue else state.section2014B1
                            state.section2014B2 = if (propertyName == EconomicPerformanceField.SECTION_2014B2) propertyValue else state.section2014B2
                            state.section2014B3 = if (propertyName == EconomicPerformanceField.SECTION_2014B3) propertyValue else state.section2014B3
                            state.section2014B4 = if (propertyName == EconomicPerformanceField.SECTION_2014B4) propertyValue else state.section2014B4
                            state.section2014B5 = if (propertyName == EconomicPerformanceField.SECTION_2014B5) propertyValue else state.section2014B5
                            state.section2014B6 = if (propertyName == EconomicPerformanceField.SECTION_2014B6) propertyValue else state.section2014B6
                            state.section2014B7 = if (propertyName == EconomicPerformanceField.SECTION_2014B7) propertyValue else state.section2014B7
                            state.section2014B8 = if (propertyName == EconomicPerformanceField.SECTION_2014B8) propertyValue else state.section2014B8
                            state.section2014Part2Field3 = if (propertyName == EconomicPerformanceField.SECTION_2014PART2_FIELD3) propertyValue else state.section2014Part2Field3

                        if(field == EconomicPerformanceField.SECTION_2012_RISK.fieldName && changeIndex != null) {
                            state.financialRisksList[changeIndex].section2012RiskA5  =  if (propertyName == EconomicPerformanceField.SECTION_2012_RISK_A5) propertyValue else state.financialRisksList[changeIndex].section2012RiskA5
                        }

                        if(field == EconomicPerformanceField.SECTION_2012_OPPORTUNITY.fieldName && changeIndex != null) {
                            state.financialOpportunitiesList[changeIndex].section2012OpportunityA5 =  if (propertyName == EconomicPerformanceField.SECTION_2012_OPPORTUNITY_A5) propertyValue else state.financialOpportunitiesList[changeIndex].section2012OpportunityA5
                        }

                        if(field == EconomicPerformanceField.SECTION_2013_RETIREMENTPLAN.fieldName && changeIndex != null) {
                            state.retirementPlansList[changeIndex].section2013A11 =  if (propertyName == EconomicPerformanceField.SECTION_2013A11) propertyValue else state.retirementPlansList[changeIndex].section2013A11
                            state.retirementPlansList[changeIndex].section2013A12 =  if (propertyName == EconomicPerformanceField.SECTION_2013A12) propertyValue else state.retirementPlansList[changeIndex].section2013A12
                        }

                        if(field == EconomicPerformanceField.SECTION_2014_COUNTRIESANDVALUES.fieldName && changeIndex != null) {
                            state.countriesAndValuesList[changeIndex].section2014Part2B1 =  if (propertyName == EconomicPerformanceField.SECTION_2014PART2_B1) propertyValue else state.countriesAndValuesList[changeIndex].section2014Part2B1
                        }

                        stateSetter(state)
                        props.setInputState(
                            CurrentInputState(
                                parentPath = field,
                                childPath = propertyName.fieldName,
                                index = changeIndex,
                                value = JsonPrimitive(propertyValue),
                                objectName = EsgSection.ECONOMIC_PERFORMANCE.name,
                                reportName = esgState.reportFileName
                            )
                        )
                    } else {
                        val propertyName = EconomicPerformanceField.valueOf(target.name)
                        val propertyValue = target.value

                        state.section2013A2 = if (propertyName == EconomicPerformanceField.SECTION_2013A2) propertyValue else state.section2013A2
                        state.section2013A5 = if (propertyName == EconomicPerformanceField.SECTION_2013A5) propertyValue else state.section2013A5
                        state.section2013A6 = if (propertyName == EconomicPerformanceField.SECTION_2013A6) propertyValue else state.section2013A6
                        state.section2013A7 = if (propertyName == EconomicPerformanceField.SECTION_2013A7) propertyValue else state.section2013A7

                        if(field == EconomicPerformanceField.SECTION_2013_RETIREMENTPLAN.fieldName && changeIndex != null) {
                            state.retirementPlansList[changeIndex].section2013A10 =  if (propertyName == EconomicPerformanceField.SECTION_2013A10) propertyValue else state.retirementPlansList[changeIndex].section2013A10
                            state.retirementPlansList[changeIndex].section2013A13 =  if (propertyName == EconomicPerformanceField.SECTION_2013A13) propertyValue else state.retirementPlansList[changeIndex].section2013A13
                            state.retirementPlansList[changeIndex].section2013A14 =  if (propertyName == EconomicPerformanceField.SECTION_2013A14) propertyValue else state.retirementPlansList[changeIndex].section2013A14
                            state.retirementPlansList[changeIndex].section2013A15 =  if (propertyName == EconomicPerformanceField.SECTION_2013A15) propertyValue else state.retirementPlansList[changeIndex].section2013A15
                        }

                        if(field == EconomicPerformanceField.SECTION_2014_COUNTRIESANDVALUES.fieldName && changeIndex != null) {
                            state.countriesAndValuesList[changeIndex].section2014Part2A1 =  if (propertyName == EconomicPerformanceField.SECTION_2014PART2_A1) propertyValue else state.countriesAndValuesList[changeIndex].section2014Part2A1
                        }

                        stateSetter(state)
                        props.setInputState(
                            CurrentInputState(
                                parentPath = field,
                                childPath = propertyName.fieldName,
                                index = changeIndex,
                                value = JsonPrimitive(propertyValue),
                                objectName = EsgSection.ECONOMIC_PERFORMANCE.name,
                                reportName = esgState.reportFileName
                            )
                        )
                    }
                }

                is HTMLTextAreaElement -> {
                    val target = event.target as HTMLTextAreaElement
                    val propertyName = EconomicPerformanceField.valueOf(target.name)
                    val propertyValue = target.value

                    if(field == EconomicPerformanceField.SECTION_2012_RISK.fieldName && changeIndex != null) {
                        state.financialRisksList[changeIndex].section2012RiskA1 =  if (propertyName == EconomicPerformanceField.SECTION_2012_RISK_A1) propertyValue else state.financialRisksList[changeIndex].section2012RiskA1
                        state.financialRisksList[changeIndex].section2012RiskA2 =  if (propertyName == EconomicPerformanceField.SECTION_2012_RISK_A2) propertyValue else state.financialRisksList[changeIndex].section2012RiskA2
                        state.financialRisksList[changeIndex].section2012RiskA3 =  if (propertyName == EconomicPerformanceField.SECTION_2012_RISK_A3) propertyValue else state.financialRisksList[changeIndex].section2012RiskA3
                        state.financialRisksList[changeIndex].section2012RiskA4 =  if (propertyName == EconomicPerformanceField.SECTION_2012_RISK_A4) propertyValue else state.financialRisksList[changeIndex].section2012RiskA4
                    }

                    if(field == EconomicPerformanceField.SECTION_2012_OPPORTUNITY.fieldName && changeIndex != null) {
                        state.financialOpportunitiesList[changeIndex].section2012OpportunityA1 =  if (propertyName == EconomicPerformanceField.SECTION_2012_OPPORTUNITY_A1) propertyValue else state.financialOpportunitiesList[changeIndex].section2012OpportunityA1
                        state.financialOpportunitiesList[changeIndex].section2012OpportunityA2 =  if (propertyName == EconomicPerformanceField.SECTION_2012_OPPORTUNITY_A2) propertyValue else state.financialOpportunitiesList[changeIndex].section2012OpportunityA2
                        state.financialOpportunitiesList[changeIndex].section2012OpportunityA3 =  if (propertyName == EconomicPerformanceField.SECTION_2012_OPPORTUNITY_A3) propertyValue else state.financialOpportunitiesList[changeIndex].section2012OpportunityA3
                        state.financialOpportunitiesList[changeIndex].section2012OpportunityA4 =  if (propertyName == EconomicPerformanceField.SECTION_2012_OPPORTUNITY_A4) propertyValue else state.financialOpportunitiesList[changeIndex].section2012OpportunityA4
                    }

                    state.section2011Field1 = if (propertyName == EconomicPerformanceField.SECTION_2011_FIELD1) propertyValue else state.section2011Field1
                    state.section2011Field2 = if (propertyName == EconomicPerformanceField.SECTION_2011_FIELD2) propertyValue else state.section2011Field2
                    state.section2012Field1 = if (propertyName == EconomicPerformanceField.SECTION_2012_FIELD1) propertyValue else state.section2012Field1
                    state.section2014Part2Field2 = if (propertyName == EconomicPerformanceField.SECTION_2014PART2_FIELD2) propertyValue else state.section2014Part2Field2

                    state.section2014A1 = if (propertyName == EconomicPerformanceField.SECTION_2014A1) propertyValue else state.section2014A1
                    state.section2014A2 = if (propertyName == EconomicPerformanceField.SECTION_2014A2) propertyValue else state.section2014A2
                    state.section2014A3 = if (propertyName == EconomicPerformanceField.SECTION_2014A3) propertyValue else state.section2014A3
                    state.section2014A4 = if (propertyName == EconomicPerformanceField.SECTION_2014A4) propertyValue else state.section2014A4
                    state.section2014A5 = if (propertyName == EconomicPerformanceField.SECTION_2014A5) propertyValue else state.section2014A5
                    state.section2014A6 = if (propertyName == EconomicPerformanceField.SECTION_2014A6) propertyValue else state.section2014A6
                    state.section2014A7 = if (propertyName == EconomicPerformanceField.SECTION_2014A7) propertyValue else state.section2014A7
                    state.section2014A8 = if (propertyName == EconomicPerformanceField.SECTION_2014A8) propertyValue else state.section2014A8

                    stateSetter(state)
                    props.setInputState(
                        CurrentInputState(
                            parentPath = field,
                            childPath = propertyName.fieldName,
                            index = changeIndex,
                            value = JsonPrimitive(propertyValue),
                            objectName = EsgSection.ECONOMIC_PERFORMANCE.name,
                            reportName = esgState.reportFileName
                        )
                    )
                }
            }
        }

        Box {
            className = styles.SECTION_MAIN_LAYOUT.cssClass

            Section201_1 {
                this.state = state
                onChange = onChangeFunction
            }

            Section201_2 {
                this.state = state
                this.stateSetter = stateSetter
                onChange = onChangeFunction
                addRow = addRowFunction
                deleteRow = deleteRowFunction
            }

            Section201_3 {
                this.state = state
                this.stateSetter = stateSetter
                onChange = onChangeFunction
                addRow = addRowFunction
                deleteRow = deleteRowFunction
            }

            Section201_4 {
                this.state = state
                this.stateSetter = stateSetter
                this.onChangeRadio = onChangeRadio
                onChange = onChangeFunction
                addRow = addRowFunction
                deleteRow = deleteRowFunction
            }
        }
    }
}
