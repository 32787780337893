package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.components.esg.formcontrols.ESGRadioButtonWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.helpers.esg.GovernanceField
import com.ecosave.watch.portal.models.esg.GovernanceState
import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.ChangeEvent
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement
import web.html.HTMLInputElement

external interface Section213Props : Props {
    var onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit
    var onChangeRadio: (ChangeEvent<HTMLInputElement>) -> Unit
    var state: GovernanceState
}

val Section2_13 = FC<Section213Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onChangeRadio = props.onChangeRadio
    val state = props.state

    SectionMainTitle {
        title = "2-13 DELEGATION OF RESPONSIBILITY FOR MANAGING IMPACTS"
    }

    Box {
        className = ESGReportInputStyles.TABLE_GRID_WRAPPER.cssClass
        Box {
            Label {
                label =
                    "Describe the role of the highest governance body in delegating responsibility for managing the organization’s impacts on the economy, environment, and people"
            }
            ESGTextAreaFieldWrapper {
                name = GovernanceField.SECTION_2_13_A1.name
                value = state.section213A1
                onChange = { event -> onChangeTextArea(event) }
            }
        }

        Box {
            className = ESGReportInputStyles.SUB_ITEM_LEFT_MARGIN.cssClass
            Label {
                label = "Has it appointed any senior executives with responsibility for the management of impacts?"
            }
            ESGRadioButtonWrapper {
                name = GovernanceField.SECTION_2_13_A2.name
                value = state.section213A2
                onChange = { event, _ -> onChangeRadio(event) }
            }
        }
        Box {
            className = ESGReportInputStyles.SUB_ITEM_LEFT_MARGIN.cssClass
            Label {
                label = "Has it appointed any senior executives with responsibility for the management of impacts?"
            }
            ESGRadioButtonWrapper {
                name = GovernanceField.SECTION_2_13_A3.name
                value = state.section213A3
                onChange = { event, _ -> onChangeRadio(event) }
            }
        }
    }
    Box {
        Label {
            label =
                "Describe the process and frequency for senior executives or other employees to report back to the highest governance body on the management of the organization’s impacts on the economy, environment, and people"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_13_A4.name
            value = state.section213A4
            onChange = { event -> onChangeTextArea(event) }
        }
    }
}