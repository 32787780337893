package com.ecosave.watch.portal.components.esg.formcontrols

import com.ecosave.watch.portal.styles.esg.ESGReportInputStyles
import react.FC
import react.Props
import react.dom.html.ReactHTML

external interface TableHeaderProps : Props {
    var header: String
}

val TableHeader = FC<TableHeaderProps> {
    ReactHTML.label {
        className = ESGReportInputStyles.TABLE_HEADER.cssClass
        +it.header
    }
}